import { Tag, TagLabel, Text } from '@chakra-ui/react'
import { CountdownResult, useCountdown } from 'hooks/useCountdown'

interface AvailableAtTagProps {
  availableAt: number
}

const timePartToString = (p: number): string => (p < 10 ? `0${p}` : String(p))

const countdownToTime = (countdown: CountdownResult): string => {
  const days = timePartToString(countdown.days)
  const hours = timePartToString(countdown.hours)
  const minutes = timePartToString(countdown.minutes)
  const seconds = timePartToString(countdown.seconds)

  if (days === '00') {
    return `${hours}:${minutes}:${seconds}`
  }
  return `${days}:${hours}:${minutes}:${seconds}`
}

export const AvailableAtTag = ({ availableAt }: AvailableAtTagProps) => {
  const countdown = useCountdown(availableAt)
  const countdownEnded = Object.values(countdown).every((t) => t <= 0)

  return (
    <Tag
      size="md"
      borderRadius="full"
      textAlign="center"
      fontSize={12}
      padding="6px 10px"
      fontWeight="Bold"
      backgroundColor="#000000"
      variant="solid"
      position="absolute"
      right="8px"
      marginTop="12px"
      alignItems="flex-start"
      border="1px"
      borderColor="gray.500"
      zIndex="banner"
    >
      <TagLabel color="#FFFFFF">
        {countdownEnded ? (
          <Text fontFamily="Bai Jamjuree" fontSize="12px" lineHeight="14px">
            Available
          </Text>
        ) : (
          <Text fontFamily="Bai Jamjuree" fontSize="12px" lineHeight="14px">
            Available in <strong>{countdownToTime(countdown)}</strong>
          </Text>
        )}
      </TagLabel>
    </Tag>
  )
}
