import { HStack, useDisclosure, Box } from '@chakra-ui/react'
import { FormEvent, useEffect, useState } from 'react'
import { Input, Button } from 'components/atoms'
import { Modal } from 'components/organisms'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useScrollPosition } from 'hooks/useScrollPosition'
import { useUser } from 'hooks/useUser'
import { useToast } from 'hooks/useToast'
import { NEWSLETTER_URL } from '../../constants'

export const NewsLetterModal = () => {
  const [hasPoped, setHasPoped] = useState(false)
  const toast = useToast()
  const { data: user } = useUser()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [newsletterSubscribed, setNewsletterSubscribed] = useLocalStorage(
    'newsletter-subscribed',
    false
  )
  const scrollPosition = useScrollPosition()

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    if (scrollPosition > 0 && !user && !newsletterSubscribed && !hasPoped) {
      timer = setTimeout(() => {
        onOpen()
        setHasPoped(true)
      }, 3000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [scrollPosition, user, onOpen, newsletterSubscribed, hasPoped, setHasPoped])

  const handleForm = (e: FormEvent) => {
    e.preventDefault()
    const data = new FormData(e.target as HTMLFormElement)
    fetch(NEWSLETTER_URL, {
      method: 'POST',
      body: data,
      mode: 'no-cors',
    })
      .then(() => {
        setNewsletterSubscribed(true)
        toast({
          title: 'Success!',
          description: 'You have been subscribed to our newsletter.',
          status: 'success',
          isClosable: true,
        })
      })
      .catch(() => {
        toast({
          title: 'Oops!',
          description: 'Something went wrong. Please try again later.',
          status: 'warning',
        })
      })
      .finally(() => {
        onClose()
      })
  }

  return (
    <Modal
      title="Sign Up for Notifications"
      description="Sign up to receive email updates from Big Time team about the latest sales, NFT, news
          and more!"
      isOpen={isOpen}
      onClose={onClose}
    >
      <form action="https://bigtime720.activehosted.com/proc.php" onSubmit={(e) => handleForm(e)}>
        <input type="hidden" name="u" value="1" />
        <input type="hidden" name="f" value="1" />
        <input type="hidden" name="s" />
        <input type="hidden" name="c" value="0" />
        <input type="hidden" name="m" value="0" />
        <input type="hidden" name="act" value="sub" />
        <input type="hidden" name="v" value="2" />
        <Input
          mt="60px"
          flex={1}
          placeholder="name@email.com"
          autoComplete="email"
          name="email"
          type="email"
          _placeholder={{ color: 'title-field', fontStyle: 'italic' }}
          display={{ md: 'none' }}
        />
        <HStack mt={5} display={{ base: 'none', md: 'flex' }}>
          <Box flex={1}>
            <Input
              placeholder="name@email.com"
              autoComplete="email"
              name="email"
              type="email"
              _placeholder={{ color: 'title-field', fontStyle: 'italic' }}
            />
          </Box>
          <Button mt={{ base: '20' }} size="full" maxW={{ md: '150px', lg: '213px' }} type="submit">
            Subscribe
          </Button>
        </HStack>
        <Box display="none" aria-hidden="true">
          <input
            readOnly
            type="text"
            name="b_4046d29fbb109e24228d4fdda_9b66bdcfae"
            tabIndex={-1}
            value=""
          />
        </Box>
      </form>
    </Modal>
  )
}
