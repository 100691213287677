import { Text, Menu, MenuButton, MenuList, MenuItem, forwardRef } from '@chakra-ui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Button } from '../Button'
import { ArrowDown } from '../Icons/ArrowDown'
import { ArrowUp } from '../Icons/ArrowUp'

const MenuButtonWrapper = forwardRef((props, ref) => (
  <Button size="auto" variant="none" ref={ref} {...props} />
))

type OptionType = {
  name: string
  url: string
}

interface DropdownMenuProps {
  label: string
  options: OptionType[]
}

// TODO: fix outside click issue
export const DropdownLinkMenu: React.FC<DropdownMenuProps> = ({ label, options }) => {
  const { pathname } = useRouter()
  const activeOption = options.find(({ url }) => pathname === url)

  return (
    <Menu>
      {({ isOpen }) => {
        const isActive = isOpen || activeOption

        return (
          <>
            <MenuButton
              sx={{ '.chakra-button__icon': { ml: 4 } }}
              color={isActive ? 'navHover' : 'nav'}
              as={MenuButtonWrapper}
              rightIcon={
                isOpen ? <ArrowUp /> : <ArrowDown color={isActive ? 'white' : '#EEEEEEB3'} />
              }
              fontWeight={isActive ? 700 : 400}
              _hover={{
                ...(!isActive && {
                  textShadow: '-0.5px 0 #fff, 0.5px 0 #fff',
                  color: 'navHover',
                  transition: 'all .5s',
                }),
              }}
            >
              <Text size="sm">{activeOption ? activeOption.name : label}</Text>
            </MenuButton>
            <MenuList
              mt="8px"
              border="none"
              borderRadius="none"
              bg="white"
              color="menu-content"
              px={5}
              pt={3}
              pb={2.5}
            >
              {options.map(({ url, name }, i) => {
                const isLastOption = i === options.length - 1

                return (
                  <Link key={i} href={url} passHref legacyBehavior>
                    <MenuItem
                      fontSize="sm"
                      fontWeight={700}
                      fontFamily="heading"
                      px={0}
                      pt={0}
                      pb={isLastOption ? 0 : 3}
                      mb={isLastOption ? 0 : 3}
                      borderBottom={isLastOption ? 'none' : '1px solid'}
                      borderColor="bright.default"
                      backgroundColor={pathname === url ? 'bright.default' : 'white'}
                    >
                      {name}
                    </MenuItem>
                  </Link>
                )
              })}
            </MenuList>
          </>
        )
      }}
    </Menu>
  )
}
