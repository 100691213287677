import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      // Montserrat
      @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url('/fonts/montserrat/montserrat-light.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/fonts/montserrat/montserrat-regular.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('/fonts/montserrat/montserrat-medium.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url('/fonts/montserrat/montserrat-semiBold.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/fonts/montserrat/montserrat-bold.ttf') format('truetype');
      }

      // Bai Jamjuree

      @font-face {
        font-family: 'Bai Jamjuree';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url('/fonts/bai-jamjuree/bai-jamjuree-light.ttf') format('truetype');
      }

      @font-face {
        font-family: 'Bai Jamjuree';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/fonts/bai-jamjuree/bai-jamjuree-regular.ttf') format('truetype');
      }

      @font-face {
        font-family: 'Bai Jamjuree';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('/fonts/bai-jamjuree/bai-jamjuree-medium.ttf') format('truetype');
      }

      @font-face {
        font-family: 'Bai Jamjuree';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url('/fonts/bai-jamjuree/bai-jamjuree-semibold.ttf') format('truetype');
      }

      @font-face {
        font-family: 'Bai Jamjuree';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/fonts/bai-jamjuree/bai-jamjuree-bold.ttf') format('truetype');
      }
    `}
  />
)

export default Fonts
