import { Text, HStack } from '@chakra-ui/react'
import { Button, Span } from 'components/atoms'
import { LogoutIcon } from 'components/atoms/Icons/Logout'
import { useCountdown } from 'hooks/useCountdown'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useLogoutMutation } from 'hooks/useLogoutMutation'
import { padStart } from 'lodash'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { User } from 'types/vault/User'
import { handleRouterUrl } from 'utils/globals'

interface AdminLoginAlertProps {
  user: User
}

const loginExpireKey = 'admin-login-expires'

const AdminLoginAlert: React.FC<AdminLoginAlertProps> = ({ user }) => {
  const { mutate } = useLogoutMutation()
  const router = useRouter()
  const [expires, setExpires, onRemoveExpires] = useLocalStorage(
    loginExpireKey,
    router.query.expires
  )

  const countdown = useCountdown(Number(expires) ?? 0)

  const countdownEnded = Object.values(countdown).every((t) => t <= 0)

  if (countdownEnded) {
    onRemoveExpires()
    handleRouterUrl({ router, removeQueryKeys: [loginExpireKey] })
    window.location.reload()
  }

  const handleLogout = () => {
    onRemoveExpires()
    mutate()
  }

  useEffect(() => {
    const queryExpires = router.query?.expires
    if (queryExpires && queryExpires !== expires) {
      setExpires(queryExpires)
    }
  }, [router.query?.expires, expires, setExpires])

  return (
    <HStack
      borderBottomRadius="md"
      overflow="hidden"
      pos="absolute"
      left="50%"
      transform="translateX(-50%)"
      top={{ base: 'calc(100% + 28px)', md: '100%' }}
      zIndex="banner"
      h={6}
      spacing="3px"
    >
      <Text size="xs" px={2} py={1} color="black" backgroundColor="fees" whiteSpace="nowrap">
        Logged in as{' '}
        <Span size="xs" fontWeight="bold">
          “{user.username}”
        </Span>{' '}
        <Span size="xs" display={{ base: 'none', sm: 'inline' }}>
          from Open Loot CS Tools
        </Span>
      </Text>
      <Text size="xs" px={2} py={1} color="white" backgroundColor="state.error" whiteSpace="nowrap">
        <Span size="xs" display={{ base: 'none', sm: 'inline' }}>
          Session will close in{' '}
        </Span>
        {countdown && (
          <Span size="xs" fontWeight="bold" w="51px" display="inline-block">
            {padStart(countdown.hours.toString(), 2, '0')}:
            {padStart(countdown.minutes.toString(), 2, '0')}:
            {padStart(countdown.seconds.toString(), 2, '0')}
          </Span>
        )}
      </Text>

      <Button onClick={handleLogout} _hover={{}} h="full" minW={6} w={6} backgroundColor="fees">
        <LogoutIcon width="15px" />
      </Button>
    </HStack>
  )
}

export default AdminLoginAlert
