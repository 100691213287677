import { KeyboardEventHandler, ReactElement, ReactNode, useCallback } from 'react'
import { Avatar, Button, ButtonProps, Flex, Radio } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import NextLink from 'next/link'

interface BoxButtonProps extends ButtonProps {
  icon?: ReactElement
  iconBg?: string
  rightIconOverride?: ReactElement | null
  children?: ReactNode
  dark?: boolean
  gray?: boolean
  href?: string
  bgColor?: string
  onClick?: () => void
  radioValue?: string | number
  radioDisabled?: boolean
}

const BoxButton = ({
  icon,
  iconBg = 'black',
  rightIconOverride, // allows for an icon to be placed on the right side of the button
  children,
  dark,
  gray,
  href,
  onClick,
  bgColor,
  radioValue,
  radioDisabled = false,
  isDisabled,
  ...styles
}: BoxButtonProps) => {
  const actualBgColor = bgColor ?? (dark ? 'gray.800' : gray ? 'whiteAlpha.150' : 'white')

  const rightIcon = rightIconOverride || (
    <Flex w={{ base: '50px', md: '100px' }} justifyContent="flex-end">
      <ChevronRightIcon color={dark ? 'white' : gray ? 'white' : 'gray.700'} w={6} h={10} />
    </Flex>
  )

  const radioSelect =
    radioValue !== undefined ? (
      <Flex w={{ base: '50px', md: '100px' }} justifyContent="flex-end">
        <Radio
          value={radioValue.toString()}
          isDisabled={radioDisabled}
          borderColor="gray.300"
          size="lg"
          _checked={{
            color: 'white',
            width: '20px',
            height: '20px',
            _before: {
              content: '""',
              position: 'absolute',
              width: '10px',
              height: '10px',
              background: 'gray.300',
              borderRadius: '50%',
            },
          }}
          _disabled={{
            color: 'white',
            width: '20px',
            height: '20px',
          }}
        />
      </Flex>
    ) : undefined

  const buttonIcon = radioSelect ?? (onClick || href ? rightIcon : undefined)

  const keyboardCallback = useCallback<KeyboardEventHandler<HTMLButtonElement>>(
    (e) => {
      if (onClick && (e.code === 'Space' || e.code === 'Enter')) {
        onClick()
      }
    },
    [onClick]
  )

  const clickable = !isDisabled && Boolean(href || onClick)

  const button = (
    <Button
      boxShadow="md"
      borderRadius="lg"
      bgColor={actualBgColor}
      cursor={clickable ? 'pointer' : 'auto'}
      flexDirection="row"
      height="auto"
      mt={0}
      onClick={isDisabled ? undefined : onClick}
      onKeyDown={keyboardCallback}
      py={6}
      px={3}
      rightIcon={buttonIcon}
      role="button"
      tabIndex={0}
      _hover={{
        bgColor: 'bgColor',
      }}
      _pressed={{
        bgColor: 'bgColor',
      }}
      isDisabled={isDisabled}
      {...styles}
    >
      {icon ? <Avatar size="md" color="white" bg={iconBg} icon={icon} mr={3} /> : null}
      <Flex direction="row" align="center" flex={1} color="gray.800">
        {children}
      </Flex>
    </Button>
  )

  if (href) {
    return <NextLink href={href}>{button}</NextLink>
  }

  return button
}

export default BoxButton
