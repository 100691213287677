import { Box, Circle, Heading, HStack, SimpleGrid } from '@chakra-ui/react'
import { Button } from 'components/atoms'

export const RecentlyListed = () => (
  <Box as="section" mt={['35px', 10, 14, 16, null, 20, '89px']}>
    <Heading as="h3" size="xl" textShadow="heroText" textAlign="center">
      Recently Listed
    </Heading>

    <SimpleGrid
      mt={[2.5, 4, 5, 6, 8, '35px']}
      columns={[2, null, 3, null, 4, 5]}
      gap={{ base: 3, md: 5, '2xl': 7, '3xl': '35px' }}
    >
      {/* {(cardMock as unknown as Listing[])?.map((option, index) => (
        <ItemCard key={index} {...option} />
      ))} */}
    </SimpleGrid>

    {/* // TODO make reuseable component when adding carousel logic */}
    <HStack justify="center" mt={2.5} spacing={2.5} display={{ base: 'flex', md: 'none' }}>
      <Button size="auto" variant="none">
        <Circle size="10px" bg="bright.default" />
      </Button>
      <Button size="auto" variant="none">
        <Circle size="10px" bg="bright.200" />
      </Button>
      <Button size="auto" variant="none">
        <Circle size="10px" bg="bright.200" />
      </Button>
    </HStack>
  </Box>
)
