import { Box, Heading, HStack, Image, Text, VStack } from '@chakra-ui/react'
import { Accordian, Button, DropdownButtonMenu } from 'components/atoms'
import { useGetAllFeatures } from 'hooks/useFeature'
import { usePartnerPortal } from 'hooks/usePartnerPortal'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { features } from 'types/vault/Features'
import { Game } from 'types/vault/v2/Game'

interface PartnerPortalNavbarType {
  name: string
  url: string
  subItems?: {
    name: string
    url: string
    isAdminUrl?: boolean
  }[]
  feature?: features
  isAdminUrl?: boolean
}

const PARTNER_PORTAL_NAV_ITEMS: PartnerPortalNavbarType[] = [
  {
    name: 'Financial',
    url: '/partner-portal/financial',
    subItems: [
      {
        name: 'Transaction Dashboard',
        url: '/partner-portal/financial/transaction-dashboard',
      },
      {
        name: 'Balance Statement',
        url: '/partner-portal/financial/balance-statement',
      },
      {
        name: 'Withdrawals',
        url: '/partner-portal/financial/withdrawals',
      },
    ],
  },
  {
    name: 'Ambassador Management',
    url: '/partner-portal/ambassador-management',
    feature: 'ambassadors',
    subItems: [
      {
        name: 'Dashboard',
        url: '/partner-portal/ambassador-management/dashboard',
      },
      {
        name: 'Playvite',
        url: '/partner-portal/ambassador-management/playvite',
      },
      {
        name: 'Ambassador Applications',
        url: '/partner-portal/ambassador-management/ambassador-applications',
        isAdminUrl: true,
      },
    ],
  },
  {
    name: 'Settings',
    url: '/partner-portal/settings',
    isAdminUrl: true,
  },
]

export const PartnerPortalSidebar = () => {
  const { selectedGame, setSelectedGame, allowedGames, isOlAdmin } = usePartnerPortal()
  const router = useRouter()
  const features = useGetAllFeatures()

  return (
    <Box bg="container" minW="19.75rem" p={6}>
      {!selectedGame && (
        <DropdownButtonMenu
          label="Select Game"
          options={allowedGames.map((game) => ({ name: game.name, value: game }))}
          onChange={(game) => {
            setSelectedGame(game as Game)
          }}
        />
      )}
      {selectedGame && (
        <>
          <HStack spacing={6}>
            {allowedGames.length > 1 && (
              <Button variant="unstyled" size="auto" onClick={() => setSelectedGame(null)}>
                <Image src="/images/arrow-prev.svg" alt="back arrow" w={5} h={5} />
              </Button>
            )}
            {selectedGame && (
              <Heading as="h2" fontSize="24px">
                {selectedGame.name}
              </Heading>
            )}
          </HStack>

          <HStack justifyContent="center" spacing={2.5} py={2.5} mt={3} bg="whiteAlpha.100">
            <Text size="sm">Status</Text>
            <Text size="sm" color="state.success">
              Ready in Production
            </Text>
          </HStack>
        </>
      )}
      <Box mt="34px">
        {PARTNER_PORTAL_NAV_ITEMS.map((item, i) => {
          const isActiveItem = router.pathname.startsWith(item.url)

          if (item.isAdminUrl && !isOlAdmin) return null

          if (item.feature && !features.includes(item.feature)) return null

          if (!item.subItems) {
            return (
              <Box
                key={i}
                pb={6}
                pt={i === 0 ? 0 : 6}
                borderBottom={i === PARTNER_PORTAL_NAV_ITEMS.length - 1 ? 'none' : '1px'}
                borderColor="table-stroke"
              >
                <Link href={item.url} passHref legacyBehavior>
                  <Text
                    fontSize="18px"
                    lineHeight="22px"
                    fontWeight={isActiveItem ? 'bold' : 'normal'}
                    color="gray.300"
                  >
                    {item.name}
                  </Text>
                </Link>
              </Box>
            )
          }

          return (
            <Accordian
              key={i}
              accordionButtonProps={{ border: 'none', p: 0 }}
              accordionPanelProps={{ px: 3, pt: 2.5, pb: 0 }}
              defaultIndex={isActiveItem ? [0] : [1]}
              label={item.name}
              maxW="unset"
              py={6}
              borderBottom="1px"
              borderColor="table-stroke"
              labelProps={{
                fontFamily: 'heading',
                fontSize: '18px',
                fontWeight: isActiveItem ? 'bold' : 'normal',
                lineHeight: '22px',
                mr: 2.5,
              }}
            >
              <VStack spacing={2.5} alignItems="stretch">
                {item.subItems
                  .filter((subItem) => (subItem.isAdminUrl ? isOlAdmin : true))
                  .map((subItem, i) => (
                    <Link href={subItem.url} key={i} passHref legacyBehavior>
                      <Text
                        fontFamily="heading"
                        fontWeight={router.pathname.startsWith(subItem.url) ? 'bold' : 'normal'}
                        lineHeight="22px"
                      >
                        {subItem.name}
                      </Text>
                    </Link>
                  ))}
              </VStack>
            </Accordian>
          )
        })}
      </Box>
    </Box>
  )
}
