import NextLink from 'next/link'
import { Fragment, ReactNode } from 'react'
import { Button, ButtonProps } from 'components/atoms'

interface ButtonLinkProps extends ButtonProps {
  href: string
  disabled?: boolean
  children?: ReactNode
  target?: string
  rel?: string
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  href,
  target,
  rel,
  disabled,
  children,
  ...props
}) => {
  if (disabled) {
    return (
      <Button disabled {...props}>
        {children}
      </Button>
    )
  }

  return (
    <NextLink href={href} target={target} passHref legacyBehavior>
      <Button as="a" rel={rel} {...props}>
        {children}
      </Button>
    </NextLink>
  )
}
