import { isNil } from 'lodash'
import { useEffect, useState } from 'react'

export type LocalStorageKey =
  | 'cookie-banner-closed'
  | 'show-crypto-deposit-instructions'
  | 'show-crypto-withdrawal-instructions'
  | 'newsletter-subscribed'
  | 'should-open-MFA-modal'
  | 'ambassador-reapply'
  | 'admin-login-expires'
  | 'default-token'
  | 'default-currency'
  | 'billionaires-checks'
  | 'hide-empty-token-balance'
  | 'hide-empty-currency-balance'

export function useLocalStorage<T>(key: LocalStorageKey, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      return initialValue
    }
  })

  const onRemoveValue = () => {
    window.localStorage.removeItem(key)
    setStoredValue(undefined as unknown as T)
  }

  useEffect(() => {
    if (isNil(storedValue)) return

    try {
      window.localStorage.setItem(key, JSON.stringify(storedValue))
    } catch (error) {
      // TODO: handle error properly
    }
  }, [key, storedValue])

  return [storedValue, setStoredValue, onRemoveValue] as const
}
