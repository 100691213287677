/* eslint-disable react/display-name */
import { Box, ButtonProps as ChakraButtonProps } from '@chakra-ui/react'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import { useRef } from 'react'
import { Button } from '../Button'

export interface FetchNextPageButtonProps extends Omit<ChakraButtonProps, 'variant' | 'size'> {
  label?: string
  fetchNextPage: () => void
  hasNextPage?: boolean
  isLoading: boolean
  variant?: string
}

export const FetchNextPageButton: React.FC<FetchNextPageButtonProps> = ({
  label = 'Show More',
  variant = 'light-outline',
  textAlign = 'center',
  children,
  hasNextPage,
  isLoading,
  fetchNextPage,
  ...props
}) => {
  const loadMoreRef = useRef(null)
  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: () => fetchNextPage(),
    enabled: hasNextPage,
  })

  return (
    <Box textAlign={textAlign ?? 'initial'}>
      {!isLoading && hasNextPage && (
        <Button variant={variant} {...props} ref={loadMoreRef} onClick={() => fetchNextPage()}>
          {label || children}
        </Button>
      )}
    </Box>
  )
}
