import { VAULT_API_URL } from '../../constants'
import ApiBase, { RequestParams } from './ApiBase'

export default class ServerSideVaultApi {
  static doRequest(params: RequestParams) {
    const ssHeaders = { 'x-serverside': 'server-side' }
    return ApiBase.doRequest(VAULT_API_URL, {
      ...params,
      headers: { ...params.headers, ...ssHeaders },
    })
  }
}
