import VaultApi from 'lib/api/VaultApi'
import { useQuery } from 'react-query'
import { features, FeaturesConfig } from 'types/vault/Features'

const fetchFeaturesConfig = (): Promise<FeaturesConfig> =>
  VaultApi.doRequest({
    method: 'GET',
    url: `/v2/market/features`,
  }).then((res) => res.json())

export const useFeatures = () => useQuery('features', fetchFeaturesConfig)

export const useFeature = (feature: features | features[]) => {
  const { data } = useFeatures()

  const hasFeature = Array.isArray(feature)
    ? feature.every((f) => data?.features[f])
    : data?.features[feature] ?? false

  return hasFeature
}

export const useFeatureAndStatus = (feature: features | features[]) => {
  const { data, status } = useFeatures()

  const hasFeature = Array.isArray(feature)
    ? feature.every((f) => data?.features[f])
    : data?.features[feature] ?? false

  return { data: hasFeature, status }
}

export const useAnyFeature = (feature: features | features[]) => {
  const { data } = useFeatures()

  const hasFeature = Array.isArray(feature)
    ? feature.some((f) => data?.features[f])
    : data?.features[feature] ?? false

  return hasFeature
}

export const useGetAllFeatures = () => {
  const { data } = useFeatures()

  if (!data) return []

  const activeFeatures = Object.entries(data.features)
    .filter(([_, isEnabled]) => isEnabled)
    .map(([feature]) => feature)

  return activeFeatures
}

export interface FeatureWithExtra {
  enabled: boolean
  extra: any
}

const fetchFeatureWithExtra = (featureName: features): Promise<FeatureWithExtra> =>
  VaultApi.doRequest({
    method: 'GET',
    url: `/v2/market/features/${featureName}/info`,
  }).then((res) => res.json())

export const useFeatureWithExtra = (featureName: features) =>
  useQuery([`feature-extra`, { featureName }], () => fetchFeatureWithExtra(featureName))
