import AuthApi from 'lib/api/AuthApi'
import { useRouter } from 'next/router'
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query'
import { useTracking } from './useTracking'

interface useLogoutMutationProps {
  options?: UseMutationOptions<unknown> | undefined
  route?: string
}

const fetchLogout = (): Promise<unknown> =>
  AuthApi.doRequest({
    method: 'POST',
    url: '/v2/auth/sign-out',
  }).then((r) => r)

export const useLogoutMutation = ({ options = {}, route = '/' }: useLogoutMutationProps = {}) => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { track } = useTracking()

  return useMutation(fetchLogout, {
    ...options,
    onSettled: () => {
      queryClient.invalidateQueries('me')
      queryClient.invalidateQueries('options')
      queryClient.invalidateQueries('features')
      queryClient.clear()
      track('logout_success')
      router.replace(route)
    },
  })
}
