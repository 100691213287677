import { createStandaloneToast, Flex } from '@chakra-ui/react'
import { AddCountryAndDobModal } from 'components/organisms/AddCountryAndDob'
import { CookieConsentBanner } from 'components/organisms/CookieConsentBanner'
import { AmbassadorLayout } from 'components/templates/layout/AmbassadorLayout'
import { PartnerPortalLayout } from 'components/templates/layout/PartnerPortalLayout'
import { useRouter } from 'next/router'
import { useIsMobile } from 'hooks/useIsMobile'
import { Login2FAModal } from '../../organisms/auth/Login2FAModal'
import { Footer, Navbar } from '../layout-components'
import { SuspendedLayout } from './SuspendedLayout'
import BillionairesClubPage from '../../../pages/billionairesclub'
import { SimpleNavbar } from '../layout-components/SimpleNavbar'

interface LayoutProps {
  children: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
  const router = useRouter()
  const { ToastContainer } = createStandaloneToast()

  const [isMobile] = useIsMobile()
  const isPartnerPortal = router.pathname.startsWith('/partner-portal')
  if (isPartnerPortal) {
    return <PartnerPortalLayout>{children}</PartnerPortalLayout>
  }

  const isAmbassadorPortal = router.pathname.startsWith('/ambassador')
  if (isAmbassadorPortal) {
    return <AmbassadorLayout>{children}</AmbassadorLayout>
  }

  const isSuspendedPage = router.pathname.startsWith('/suspended')
  if (isSuspendedPage) {
    return <SuspendedLayout>{children}</SuspendedLayout>
  }

  const isBilionairePage = router.pathname.startsWith('/billionaires')

  const isConsentPage = router.pathname === '/consent'
  const isDownloadPageMobile = router.pathname === '/download' && isMobile
  const hiddenFooter = isConsentPage || isDownloadPageMobile
  const isTermOfUsePage = router.pathname === '/terms-of-use'
  if (isBilionairePage) {
    return <BillionairesClubPage />
  }

  const hideNavBarRightButtons =
    router.pathname.startsWith('/terms-of-use') || router.pathname.startsWith('/privacy-policy')

  return (
    <Flex direction="column" minH="100vh">
      {hideNavBarRightButtons ? <SimpleNavbar /> : <Navbar />}
      <Flex as="main" flexGrow={1} direction="column">
        {children}
      </Flex>
      {/* <NewsLetterModal /> */}
      <ToastContainer />
      <Login2FAModal />
      <CookieConsentBanner />
      {!isTermOfUsePage && <AddCountryAndDobModal />}
      {!hiddenFooter && <Footer />}
    </Flex>
  )
}
