import { SimpleGrid, Box, Text, Flex } from '@chakra-ui/react'
import TimeBox from './TimeBox'
import { useCountdown } from '../../../hooks/useCountdown'
import { CountdownBox } from './CountdownBox'

interface CountdownProps {
  deadline: number
  children?: JSX.Element
  align?: 'left' | 'right' | 'center'
  direction?: 'column' | 'row'
  styles?: React.CSSProperties
  fontSize?: string
  text?: string
  countdownBoxSize?: string
}

export const SaleCardCountDown = ({
  deadline,
  align = 'left',
  children,
  styles,
  direction = 'column',
  fontSize = '16px',
  text = 'Available in',
  countdownBoxSize,
}: CountdownProps) => {
  const countdown = useCountdown(deadline)

  const countdownEnded = Object.values(countdown).every((t) => t <= 0)

  if (countdownEnded && children) {
    return <Box textAlign={align}>{children}</Box>
  }

  return (
    <Flex style={styles} direction={direction}>
      <Text
        color="#FFD32A"
        fontSize={fontSize}
        align={align}
        as="b"
        mr={align === 'center' ? 0 : { md: 5, xl: 7, '2xl': '35px' }}
        mb={{ base: 3, md: 0 }}
        lineHeight="24px"
      >
        {text}
      </Text>
      <Flex direction="row" gridGap="10px">
        <CountdownBox
          label="Days"
          time={countdown.days}
          h={countdownBoxSize}
          w={countdownBoxSize}
        />
        <CountdownBox
          label="Hrs"
          time={countdown.hours}
          h={countdownBoxSize}
          w={countdownBoxSize}
        />
        <CountdownBox
          label="Min"
          time={countdown.minutes}
          h={countdownBoxSize}
          w={countdownBoxSize}
        />
        <CountdownBox
          label="Sec"
          time={countdown.seconds}
          h={countdownBoxSize}
          w={countdownBoxSize}
        />
      </Flex>
    </Flex>
  )
}

export const CountDown = () => (
  <SimpleGrid columns={4} mt={3} gap={2.5}>
    <TimeBox number={4} title="Days" />
    <TimeBox number={7} title="Hrs" />
    <TimeBox number={43} title="Min" />
    <TimeBox number={11} title="Sec" />
  </SimpleGrid>
)
