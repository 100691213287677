import { useFeatureAndStatus } from 'hooks/useFeature'
import { features } from 'types/vault/Features'
import FourOhFour from '../../../pages/404'

interface Props {
  children: JSX.Element
  features: features[]
  redirectFourOhFour?: boolean
}

export const RequireFeaturesAccessible = ({
  children,
  features,
  redirectFourOhFour = true,
}: Props) => {
  const { data: isAccessible, status } = useFeatureAndStatus(features)

  if (isAccessible) {
    return children
  }

  if (redirectFourOhFour && (status === 'success' || status === 'error')) {
    return <FourOhFour />
  }

  return null
}
