/* eslint-disable react/display-name */
import { Box, BoxProps, Heading, HStack, Image, Slide, StackProps, Text } from '@chakra-ui/react'
import { BottomAction, Button } from 'components/atoms'
import {
  CategoryFilter,
  GameFilter,
  MaxSupplyFilter,
  OnSaleFilter,
  PriceFilter,
  RarityFilter,
} from 'components/organisms/filter-menu'
import { InUseItemsFilter } from 'components/organisms/filter-menu/InUseItemsFilter'
import { NFTTagFilter } from 'components/organisms/filter-menu/NFTTagFilter'
import { useDesktop } from 'hooks/useDesktop'
import { useFilterMenu } from 'hooks/useFilterMenu'
import useLockBodyScroll from 'hooks/useLockBodyScroll'

export type SkipedFilter = 'game' | 'maxSupply' | 'price' | 'onSale' | 'nftTag' | 'inUseItems'

interface FilterMenuProps extends StackProps {
  children?: React.ReactNode
  showMobileFilterMenu?: boolean
  showAllGames?: boolean
  onCloseMobileMenu?: () => void
  wrapperProps?: BoxProps
  skipFilters?: SkipedFilter[]
  gameFilterType?: 'single-select' | 'single-thumbnail-select' | 'multi-thumbnails'
}

export const FilterMenu: React.FC<FilterMenuProps> = ({
  children,
  onCloseMobileMenu,
  wrapperProps,
  showMobileFilterMenu = false,
  showAllGames = false,
  skipFilters = [],
  gameFilterType = 'multi-thumbnails',
  ...props
}) => {
  const isDesktop = useDesktop()

  const { handlePushFiltersToRouter, handleClearFilters, isAnyFilterSelected } = useFilterMenu()

  useLockBodyScroll({ lock: isDesktop ? false : showMobileFilterMenu })

  const skipGameFilter = skipFilters.includes('game')
  const skipMaxSupplyFilter = skipFilters.includes('maxSupply')
  const skipPriceFilter = skipFilters.includes('price')
  const skipOnSaleFilter = skipFilters.includes('onSale')
  const skipNFTTagFilter = skipFilters.includes('nftTag')
  const skipInUseItemsFilter = skipFilters.includes('inUseItems')

  return (
    <Box
      // make space for filter menu
      minW={{
        base: '200px',
        md: '236px',
        lg: '269px',
        xl: '277px',
        '2xl': '301px',
        '3xl': '349px',
      }}
      flexShrink={0}
      sx={{
        '.chakra-slide': {
          zIndex: {
            base: 'modal',
            md: 0,
          },
          position: {
            base: 'fixed',
            md: 'static !important',
          },
        },
      }}
      pos={{ base: 'static', md: 'sticky' }}
      top="-360px"
      pt={{ md: '460px' }}
      zIndex="overlay"
      {...wrapperProps}
    >
      <Slide in={isDesktop || showMobileFilterMenu} direction="left">
        <Box
          pos={{ base: 'fixed', md: 'static' }}
          w="full"
          bg="black"
          zIndex="modal"
          pt={{ base: '86px', md: 0 }}
          pr={[2.5, 7, 10, 4]}
          pl={[2.5, 7, 10, 0]}
          maxW={{ base: 'full', md: '200px', lg: '237px' }}
          overflow="auto"
          h={{ base: 'full', md: 'calc(100vh - 100px)' }}
          // sx={{
          //   '&::-webkit-scrollbar': {
          //     display: 'none',
          //   },
          //   '&::-ms-overflow-style': 'none' /* IE and Edge */,
          //   '&::scrollbar-width': 'none',
          // }}
          {...props}
        >
          <Box maxW="450px" mx="auto" pb={{ base: '114px', xs: '120px', md: 0 }}>
            <Box mb={4} display={{ base: 'block', md: 'none' }}>
              {children}
              <HStack justifyContent="space-between" mt={5}>
                <Heading size="xxl">Filters</Heading>

                <Button variant="none" size="auto" flexShrink={0} onClick={onCloseMobileMenu}>
                  <Image src="/images/icons/close.svg" alt="close icon" />
                </Button>
              </HStack>

              <HStack
                mt={{ base: 5, sm: 6, lg: '14px' }}
                justifyContent="space-between"
                spacing="23px"
              >
                <Image src="/images/icons/filters-selected.svg" alt="filter icon" flexShrink={0} />
                <Button
                  size="auto"
                  variant="none"
                  flexShrink={0}
                  onClick={handleClearFilters}
                  fontWeight="400"
                  opacity="1 !important"
                  color={isAnyFilterSelected ? 'highlight.default' : 'gray.500'}
                  disabled={!isAnyFilterSelected}
                >
                  <Text as="span" fontWeight="normal">
                    Clear All
                  </Text>
                </Button>
              </HStack>
            </Box>

            <Box display={{ base: 'none', md: 'block' }} textAlign="right">
              <HStack mb={5} spacing={2.5}>
                <Image src="/images/icons/filter-border.svg" alt="filter icon" />
                <Heading textTransform="uppercase" size="base">
                  Filters
                </Heading>
              </HStack>

              <Button
                size="auto"
                variant="none"
                mb={5}
                onClick={handleClearFilters}
                textAlign="right"
                fontWeight={isAnyFilterSelected ? 700 : 400}
                opacity="1 !important"
                color={isAnyFilterSelected ? 'highlight.default' : 'gray.500'}
                disabled={!isAnyFilterSelected}
              >
                <Text as="span">Clear All</Text>
              </Button>
            </Box>

            {!skipGameFilter && (
              <GameFilter gameFilterType={gameFilterType} showAllGames={showAllGames} />
            )}

            <Box mt={skipGameFilter ? 0 : { base: '17px', md: '38px' }}>
              <CategoryFilter />
            </Box>

            {!skipInUseItemsFilter && (
              <Box mt={10}>
                <InUseItemsFilter />
              </Box>
            )}

            {!skipNFTTagFilter && (
              <Box mt={skipGameFilter ? 0 : { base: '17px', md: '38px' }}>
                <NFTTagFilter />
              </Box>
            )}

            <Box mt={10}>
              <RarityFilter />
            </Box>

            {!skipMaxSupplyFilter && (
              <Box mt="38px">
                <MaxSupplyFilter />
              </Box>
            )}

            {!skipPriceFilter && (
              <Box mt="38px">
                <PriceFilter />
              </Box>
            )}

            {!skipOnSaleFilter && (
              <Box mt="38px">
                <OnSaleFilter />
              </Box>
            )}

            <BottomAction display={{ md: 'none' }}>
              <Button
                size="md"
                w="full"
                onClick={() => {
                  handlePushFiltersToRouter()
                  if (onCloseMobileMenu) onCloseMobileMenu()
                }}
              >
                Apply Filters
              </Button>
            </BottomAction>
          </Box>
        </Box>
      </Slide>
    </Box>
  )
}
