import { TextProps } from '@chakra-ui/react'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { ReactNode } from 'react'
import { Span } from 'components/atoms/Span'

interface LinkProps extends NextLinkProps {
  children: ReactNode
  styleProps?: TextProps
}

export const Link: React.FC<LinkProps> = ({ href, children, styleProps, ...props }) => (
  <NextLink href={href} passHref legacyBehavior {...props}>
    <Span {...styleProps}>{children}</Span>
  </NextLink>
)
