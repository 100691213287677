import { Box, Container, Divider, Flex, HStack, Image, Link, Text } from '@chakra-ui/react'
import NextLink from 'next/link'

const SocialLinks: { label: string; href: string; imageSrc: string }[] = [
  {
    label: 'Twitter logo',
    href: 'https://twitter.com/openloot',
    imageSrc: '/images/icons/social/twitter-white.svg',
  },
  {
    label: 'Discord logo',
    href: 'https://discord.com/invite/openloot',
    imageSrc: '/images/icons/social/discord_white.svg',
  },
  {
    label: 'Medium logo',
    href: 'https://medium.com/@Openloot',
    imageSrc: '/images/icons/social/medium.svg',
  },
]

const getCurrentYear = () => new Date().getFullYear()

export const Footer = () => (
  <Box as="footer">
    <Flex minH="60px" bg="gray.800">
      <Container maxW="container.xl" mt={[2, 0]}>
        <Flex
          align="center"
          height="full"
          flexDir={{ base: 'column', md: 'row' }}
          p={5}
          mx="auto"
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack
            color="gray.default"
            spacing={[3, 4]}
            flexWrap="wrap"
            mx={{ base: 'auto', md: 0 }}
            maxW={{ base: '220px', md: 'unset' }}
            justifyContent="center"
          >
            <Link
              href="https://openloot.zendesk.com/hc"
              target="_blank"
              _hover={{
                textDecoration: 'none',
              }}
            >
              <Text fontWeight="light" fontSize="sm" lineHeight="">
                Customer Support
              </Text>
            </Link>
            <Divider orientation="vertical" h={4} bg="divider-light" />
            <NextLink href="/terms-of-use" passHref legacyBehavior>
              <Text fontWeight="light" fontSize="sm">
                Terms
              </Text>
            </NextLink>
            <Divider orientation="vertical" h={4} bg="divider-light" />
            <NextLink href="/privacy-policy" passHref legacyBehavior>
              <Text fontWeight="light" fontSize="sm">
                Privacy
              </Text>
            </NextLink>
            <Divider orientation="vertical" h={4} bg="divider-light" />
            <Link
              href="https://about.openloot.com/funding"
              target="_blank"
              _hover={{
                textDecoration: 'none',
              }}
            >
              <Text fontWeight="light" fontSize="sm">
                Funding
              </Text>
            </Link>
          </HStack>
          <Divider bg="divider" my="19px" display={{ md: 'none' }} />
          <HStack spacing={{ base: 7, md: 5, lg: 7 }} justifyContent="center">
            {SocialLinks.map(({ label, href, imageSrc }) => (
              <NextLink href={href} key={label} passHref legacyBehavior>
                <Link href={href} key={label}>
                  <Image cursor="pointer" src={imageSrc} alt={label} />
                </Link>
              </NextLink>
            ))}
          </HStack>
          <Divider bg="divider" my={4} display={{ md: 'none' }} />
          <HStack
            flexDir={{ base: 'column', md: 'row' }}
            spacing={{ base: 0, md: 4 }}
            justifyContent="center"
            order={{ md: -1 }}
          >
            <Text size="sm" lineHeight="20px" fontWeight="300">
              © {getCurrentYear()} Open Loot
            </Text>
            <Divider
              orientation="vertical"
              h={4}
              bg="divider-light"
              display={{ base: 'none', md: 'flex' }}
            />
            <Text
              pt={{ base: '5px', md: 0 }}
              size="xs"
              lineHeight="20px"
              fontWeight="400"
              color="gray.500"
            >
              US Patent. No. 10,946,283 B1
            </Text>
          </HStack>
        </Flex>
      </Container>
    </Flex>
  </Box>
)
