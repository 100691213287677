import { useDisclosure } from '@chakra-ui/react'
import { Button } from 'components/atoms'
import { VerificationModalModal } from '../Modal/VerificationModal'

export const AdvancedVerifyButton = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      <Button
        variant="light-outline"
        size="responsive-border"
        onClick={(e) => {
          e.stopPropagation()
          onOpen()
        }}
      >
        Verify
      </Button>

      <VerificationModalModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}
