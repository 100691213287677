import NextLink from 'next/link'
import { Box, HStack, Image, Link } from '@chakra-ui/react'

export const SimpleNavbar = () => (
  <Box pos="relative">
    <Box
      bg="blackAlpha.600"
      boxShadow="headerMenu"
      as="header"
      zIndex="popover"
      backdropFilter="blur(7px)"
      pos="sticky"
      top={0}
    >
      <HStack
        h={{ base: '72px', lg: '84px', '3xl': '105px' }}
        px={[2.5, 3, 5, 7, '15px']}
        justify={{ base: 'center', md: 'space-between' }}
      >
        <NextLink href="/" passHref replace legacyBehavior>
          <Link href="/">
            <Image
              width="154px"
              height="46px"
              src="/images/open-loot-logo.svg"
              alt="open loot logo"
              cursor="pointer"
            />
          </Link>
        </NextLink>
      </HStack>
    </Box>
  </Box>
)
