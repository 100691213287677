import {
  Radio as ChakraRadio,
  RadioGroup as ChakraRadioGroup,
  VStack,
  Button,
  Text,
} from '@chakra-ui/react'

interface RadioGroupProps {
  value?: string
  onChange?(nextValue: string | any): void
  options: { label: string | React.ReactNode; value: string; isDisabled?: boolean }[]
}

interface RadioProps {
  isSelected: boolean
  isDisabled?: boolean
  value: string
  children: string | React.ReactNode
  onChange?(nextValue: string): void
}

export const Radio = ({ value, onChange, children, isSelected, isDisabled }: RadioProps) => (
  <Button
    size="none"
    variant="none"
    alignItems="center"
    justifyContent="space-between"
    display="flex"
    fontWeight="normal"
    opacity={isSelected ? 1 : 0.4}
    onClick={() => onChange?.(value)}
  >
    {typeof children === 'string' ? <Text size="sm">{children}</Text> : children}

    <ChakraRadio
      cursor="pointer"
      value={value}
      isDisabled={isDisabled}
      borderColor="gray.300"
      size="lg"
      _checked={{
        color: 'white',
        width: '20px',
        height: '20px',
        _before: {
          content: '""',
          position: 'absolute',
          width: '10px',
          height: '10px',
          background: 'gray.300',
          borderRadius: '50%',
        },
      }}
    />
  </Button>
)

export const RadioGroup = ({ value: selectedValue, onChange, options }: RadioGroupProps) => (
  <ChakraRadioGroup onChange={onChange} value={selectedValue}>
    <VStack alignItems="stretch" spacing={2.5}>
      {options.map(({ label, value, isDisabled }) => (
        <Radio
          key={value}
          value={value}
          onChange={onChange}
          isSelected={value === selectedValue}
          isDisabled={isDisabled}
        >
          {label}
        </Radio>
      ))}
    </VStack>
  </ChakraRadioGroup>
)
