import { Box, Text, Square } from '@chakra-ui/react'

interface TimeBoxProps {
  number: number
  title: string
}

const TimeBox: React.FC<TimeBoxProps> = ({ number, title }) => (
  <Box>
    <Square
      size="30px"
      as="span"
      border="1px"
      borderColor="whiteAlpha.500"
      borderRadius="2px"
      lineHeight="18px"
      fontWeight="medium"
      fontFamily="heading"
    >
      {number.toString().padStart(2, '0')}
    </Square>
    <Text mt="3px" size="sm" color="gray.300" whiteSpace="nowrap">
      {title}
    </Text>
  </Box>
)

export default TimeBox
