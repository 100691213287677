import { Box, Checkbox, Flex, Image, Text } from '@chakra-ui/react'
import { CustomSelect } from 'components/atoms'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { formatNumber } from 'lib/format'
import Link from 'next/link'
import { useEffect } from 'react'
import { QueryResult } from 'types/QueryResult'
import { Policies } from 'types/vault/Policies'
import { Paginated } from 'types/vault/v2/Paginated'
import { PremiumCurrency } from 'types/vault/v2/PremiumCurrency'
import { Token } from 'types/vault/v2/Token'
import {
  BIGTIME_TOKEN_ID,
  OPENLOOT_REWARD_POINTS_ID,
  OPENLOOT_TOKEN_ID,
  TIME_CRYSTAL_ID,
} from '../../../constants'
import { User } from '../../../types/vault/User'

interface CurrencyItemProps {
  name: string
  icon: string
  balance: number
  href: string
  selected: boolean
  disableLink: boolean
}

const getBlackIconUrl = (url: string) => {
  // TODO - We might need a standard way to get the black version of the icon
  if (url.endsWith('.svg')) return url.replace(/\.svg$/, '-black.svg')
  return url
}

const CurrencyItem = ({ name, icon, balance, href, selected, disableLink }: CurrencyItemProps) => (
  <Flex flexDir="row" cursor="pointer" py={1}>
    <Text
      fontWeight="700"
      color="gray.500"
      fontSize="12px"
      lineHeight="16px"
      mr={2}
      flex={1}
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
      title={name}
    >
      {selected && !disableLink ? (
        <Link href={href} legacyBehavior>
          {name}
        </Link>
      ) : (
        name
      )}
    </Text>
    <Flex w={{ base: undefined, md: selected ? undefined : '88px' }}>
      <Image
        display="flex"
        justify-content="center"
        align-items="center"
        gap="10px"
        width="16px"
        height="16px"
        src={selected ? icon : getBlackIconUrl(icon)}
        fallbackSrc={icon}
        mx={1}
        alt={name}
      />
      <Text
        fontWeight="700"
        color={selected ? 'white' : 'black'}
        fontSize="12px"
        lineHeight="16px"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        title={formatNumber(balance)}
      >
        {formatNumber(balance)}
      </Text>
    </Flex>
  </Flex>
)

const getCurrencyUrl = (currency: PremiumCurrency) => {
  switch (currency.type) {
    case 'tradeable':
      return `/wallet/premium-currencies/marketplace/${currency.id}?tab=buy`
    case 'sellable':
      return `/wallet/premium-currencies/${currency.id}`
    case 'convertible':
      return `/wallet/premium-currencies/${currency.id}/convert`
    default:
      return ''
  }
}

interface TokensWidgetProps {
  user: User
  policies: Policies
  tokenList?: QueryResult<Token>
  currencyList?: Paginated<PremiumCurrency>
}

export const TokensWidget = ({ user, policies, tokenList, currencyList }: TokensWidgetProps) => {
  const tokens = tokenList?.items ?? []
  const currencies = currencyList?.items ?? []
  const canConvert = user?.kyc?.status === 'success' || policies?.canUseOpenlootToken

  const [defaultTokenId, setDefaultTokenId] = useLocalStorage('default-token', BIGTIME_TOKEN_ID)
  const [defaultCurrencyId, setDefaultCurrencyId] = useLocalStorage(
    'default-currency',
    TIME_CRYSTAL_ID
  )
  const [hideTokenOptions, setHideTokenOptions] = useLocalStorage('hide-empty-token-balance', false)
  const [hideCurrencyOptions, setHideCurrencyOptions] = useLocalStorage(
    'hide-empty-currency-balance',
    false
  )
  const getTokenBalance = (tokenId: string) =>
    user?.userTokens.find((item) => item.tokenId === tokenId)?.balance ?? 0
  const getCurrencyBalance = (currencyId: string) =>
    user?.premiumCurrencyBalances.find((item) => item.premiumCurrencyId === currencyId)?.amount ?? 0

  const filteredTokens = tokens.filter(
    (token) =>
      !hideTokenOptions ||
      token.id === OPENLOOT_TOKEN_ID ||
      token.id === BIGTIME_TOKEN_ID ||
      getTokenBalance(token.id) > 0
  )
  const filteredCurrencies = currencies.filter(
    (currency) =>
      !hideCurrencyOptions ||
      currency.id === OPENLOOT_REWARD_POINTS_ID ||
      currency.id === TIME_CRYSTAL_ID ||
      getCurrencyBalance(currency.id) > 0
  )

  useEffect(() => {
    if (!filteredTokens.some((token) => token.id === defaultTokenId)) {
      if (filteredTokens.length > 0) {
        setDefaultTokenId(filteredTokens[0].id)
      } else {
        setDefaultTokenId('')
      }
    }
  }, [filteredTokens, defaultTokenId, setDefaultTokenId])

  useEffect(() => {
    if (!filteredCurrencies.some((currency) => currency.id === defaultCurrencyId)) {
      if (filteredCurrencies.length > 0) {
        setDefaultCurrencyId(filteredCurrencies[0].id)
      } else {
        setDefaultCurrencyId('')
      }
    }
  }, [filteredCurrencies, defaultCurrencyId, setDefaultCurrencyId])

  return (
    <Box
      zIndex="sticky"
      pos="absolute"
      right={{ base: undefined, md: 0 }}
      top={{ base: '75px', lg: '87px', '3xl': '108px' }}
      h={0}
    >
      <Flex
        direction="row"
        justifyContent={{ base: 'space-evenly', md: 'flex-end' }}
        mx={3}
        my={1}
        float={{ base: 'none', md: 'right' }}
        background="black"
        borderRadius="40px"
        border="1px solid"
        borderColor="whiteAlpha.400"
        px={3}
      >
        <CustomSelect
          position="left"
          filter={{
            label: (
              <Flex
                align="center"
                w="100%"
                justify="space-between"
                py={1}
                borderBottom="1px solid"
                borderColor="blackAlpha.200"
              >
                <Text
                  fontWeight={hideTokenOptions ? 'bold' : '500'}
                  color="#ED8936"
                  fontFamily="Bai Jamjuree"
                  fontSize="12px"
                  lineHeight="12px"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  mr={2}
                >
                  Hide empty balances
                </Text>
                <Checkbox
                  colorScheme="#ED8936"
                  variant="unstyled"
                  size="md"
                  id="filter-options"
                  isChecked={hideTokenOptions}
                  onChange={(e) => setHideTokenOptions(e.target.checked)}
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    borderWidth: '1px',
                    borderColor: hideTokenOptions ? '#ED8936' : 'gray.300',
                    backgroundColor: hideTokenOptions ? '#ED8936' : 'white',
                    _hover: {
                      borderColor: hideTokenOptions ? '#ED8936' : 'gray.400',
                    },
                    _focus: {
                      borderColor: '#ED8936',
                    },
                    borderRadius: '2px',
                  }}
                  iconColor={hideTokenOptions ? 'white' : 'gray.300'}
                  icon={
                    hideTokenOptions ? (
                      <Box as="span" color="white" />
                    ) : (
                      <Box as="span" color="gray.300" />
                    )
                  }
                />
              </Flex>
            ),
            id: 'checkbox-option',
          }}
          options={filteredTokens.map((token) => ({
            label: (
              <CurrencyItem
                key={token.tokenId}
                name={token.name}
                icon={token.icon}
                selected={token.id === defaultTokenId}
                disableLink={false}
                balance={getTokenBalance(token.id)}
                href="/wallet/tokens"
              />
            ),
            id: token.id,
          }))}
          defaultSelectedIndex={filteredTokens.findIndex((token) => token.id === defaultTokenId)}
          onSelect={({ id }) => {
            if (id === 'checkbox-option') {
              return
            }
            setDefaultTokenId(id)
          }}
          key={defaultTokenId}
        />
        <Box borderRight="1px solid" borderColor="whiteAlpha.400" mx={3} />
        <CustomSelect
          position="right"
          filter={{
            label: (
              <Flex
                align="center"
                w="100%"
                justify="space-between"
                py={1}
                borderBottom="1px solid"
                borderColor="blackAlpha.200"
              >
                <Text
                  fontWeight={hideCurrencyOptions ? 'bold' : '500'}
                  color="highlight.default"
                  fontFamily="Bai Jamjuree"
                  fontSize="12px"
                  lineHeight="12px"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  mr={2}
                >
                  Hide empty balances
                </Text>
                <Checkbox
                  colorScheme="highlight.default"
                  variant="unstyled"
                  size="md"
                  id="filter-options"
                  isChecked={hideCurrencyOptions}
                  onChange={(e) => setHideCurrencyOptions(e.target.checked)}
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    borderWidth: '1px',
                    borderColor: hideCurrencyOptions ? 'highlight.default' : 'gray.300',
                    backgroundColor: hideCurrencyOptions ? 'highlight.default' : 'white',
                    _hover: {
                      borderColor: hideCurrencyOptions ? 'highlight.default' : 'gray.400',
                    },
                    _focus: {
                      borderColor: 'highlight.default',
                    },
                    borderRadius: '2px',
                  }}
                  iconColor={hideCurrencyOptions ? 'white' : 'gray.300'}
                  icon={
                    hideCurrencyOptions ? (
                      <Box as="span" color="white" />
                    ) : (
                      <Box as="span" color="gray.300" />
                    )
                  }
                />
              </Flex>
            ),
            id: 'checkbox-option',
          }}
          options={filteredCurrencies.map((currency) => ({
            label: (
              <CurrencyItem
                key={currency.id}
                name={currency.name ?? ''}
                icon={currency.icon ?? ''}
                selected={currency.id === defaultCurrencyId}
                disableLink={currency.type === 'convertible' && !canConvert}
                balance={getCurrencyBalance(currency.id)}
                href={getCurrencyUrl(currency)}
              />
            ),
            id: currency.id,
          }))}
          defaultSelectedIndex={filteredCurrencies.findIndex(
            (currency) => currency.id === defaultCurrencyId
          )}
          onSelect={({ id }) => {
            if (id === 'checkbox-option') {
              return
            }
            setDefaultCurrencyId(id)
          }}
          key={defaultCurrencyId}
        />
      </Flex>
    </Box>
  )
}
