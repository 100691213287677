import amplitude, { AmplitudeClient } from 'amplitude-js'
import Hotjar from '@hotjar/browser'
import TagManager from 'react-gtm-module'
import { createContext, ReactNode, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  AMPLITUDE_API_KEY,
  ENABLE_AMPLITUDE_TRACKING,
  HOTJAR_ID,
  TAG_MANAGER_ID,
} from '../constants'
import { addRiskifiedScriptToDocument } from './riskified'
import { getDeviceId } from '../lib/api/GetDeviceId'

function noop() {
  // noop
}

export const TrackingContext = createContext<{
  userId: string | null
  setUserId: (userId: string | null) => void
  amplitude: AmplitudeClient | undefined
  hotjar: typeof Hotjar | undefined
  tagManager: typeof TagManager | undefined
}>({
  userId: null,
  setUserId: noop,
  amplitude: undefined,
  hotjar: undefined,
  tagManager: undefined,
})

export const TrackingProvider = ({ children }: { children: ReactNode }) => {
  const [userId, setUserId] = useState<string | null>(null)
  const [amplitudeInstance, setAmplitudeInstance] = useState<AmplitudeClient | undefined>(undefined)
  const [isRiskifiedLoaded, setIsRiskifiedLoaded] = useState<boolean>(false)
  const [hotjarInstance, setHotjarInstance] = useState<typeof Hotjar | undefined>(undefined)
  const [tagManagerInstance, setTagManagerInstance] = useState<typeof TagManager | undefined>(
    undefined
  )
  const router = useRouter()

  useEffect(() => {
    if (!amplitudeInstance && ENABLE_AMPLITUDE_TRACKING) {
      const instance = amplitude.getInstance()
      const config: amplitude.Config = {
        includeGclid: true,
        includeFbclid: true,
        includeUtm: true,
        includeReferrer: true,
        sameSiteCookie: 'Lax',
      }
      instance.init(AMPLITUDE_API_KEY, undefined, config)

      setAmplitudeInstance(instance)
    }
    if (!hotjarInstance) {
      const HotjarVersion = 6
      Hotjar.init(HOTJAR_ID, HotjarVersion)

      setHotjarInstance(Hotjar)
    }
    if (!tagManagerInstance) {
      TagManager.initialize({
        gtmId: TAG_MANAGER_ID,
      })

      setTagManagerInstance(TagManager)
    }

    if (!isRiskifiedLoaded) {
      addRiskifiedScriptToDocument(getDeviceId())
      setIsRiskifiedLoaded(true)
    }
  }, [
    amplitudeInstance,
    setAmplitudeInstance,
    hotjarInstance,
    setHotjarInstance,
    tagManagerInstance,
    setTagManagerInstance,
    isRiskifiedLoaded,
    setIsRiskifiedLoaded,
    router,
  ])

  return (
    <TrackingContext.Provider
      value={{
        userId,
        setUserId,
        amplitude: amplitudeInstance,
        hotjar: hotjarInstance,
        tagManager: tagManagerInstance,
      }}
    >
      {children}
    </TrackingContext.Provider>
  )
}
