import { UnorderedList, ListItem, Text } from '@chakra-ui/react'
import Link from 'next/link'
import Accordion from '../Accordion'

type OptionType = {
  name: string
  url: string
}

interface MobileDropdownMenuProps {
  label: string
  options: OptionType[]
  onClose?(): void
}

export const MobileDropdownMenu: React.FC<MobileDropdownMenuProps> = ({
  label,
  options,
  onClose,
}) => (
  <Accordion
    defaultIndex={[1]}
    mt={3}
    pb={3}
    label={label}
    accordionButtonProps={{ borderBottom: 'none', pb: 0, pr: 1 }}
    accordionPanelProps={{ py: 0, px: 2.5 }}
    borderBottom="1px"
    borderColor="whiteAlpha.300"
  >
    <UnorderedList listStyleType="none" m={0}>
      {options.map(({ name, url }) => (
        <ListItem borderColor="whiteAlpha.300" cursor="pointer" pt={3} key={url} onClick={onClose}>
          <Link href={url} passHref legacyBehavior>
            <Text fontFamily="heading">{name}</Text>
          </Link>
        </ListItem>
      ))}
    </UnorderedList>
  </Accordion>
)
