import { Box, Flex, Heading, Link, Text } from '@chakra-ui/react'
import Head from 'next/head'
import { formatHtmlTitle } from 'utils/globals'
import { Breadcrumb, Button } from '../atoms'
import { Container } from './layout'

export const PrivacyPolicy = () => (
  <>
    <Head>
      <title key="title">{formatHtmlTitle('Privacy Policy')}</title>
    </Head>
    <Container
      mt={{ base: '18px', md: '22px' }}
      pb="77px"
      baseMaxWidth="1280px"
      extraPaddingX="2px"
    >
      <Breadcrumb items={[{ label: 'Terms of Use', href: '/terms-of-use' }]} />
      <Heading color="white" fontSize={['30px', '40px', '50px']} mb={5} mt={6}>
        Privacy Policy
      </Heading>
      <Text as="i" mb={10}>
        Updated December 29, 2023
      </Text>
      <Box overflowY="auto" maxH="400px">
        <Box fontSize="xs" fontWeight="500">
          <Text fontWeight="bold" fontSize="xl" my={2}>
            1. INTRODUCTION
          </Text>
          <Text>
            1.1 Open Loot LLC ("OL") recognizes that people who use our products value their
            privacy. This Privacy Policy details important information regarding the collection, use
            and disclosure of your personal data collected on the vault (the "Vault"), the
            marketplace (the "Marketplace"), the website located at www.openloot.com (the "Site")
            where users can access the games available (the "Games") provided by the entities which
            operate the Games (the "Developers"). OL provides this Privacy Policy to help you
            understand how your personal information is used by us and your choices regarding our
            use of it. By using the Vault or the Marketplace, you agree that we can collect, use,
            disclose, and process your information as described in this Privacy Policy. This Privacy
            Policy only applies to the Vault and Marketplace, and not to any other websites,
            products or services you may be able to access or link to via the Vault and Marketplace.
            We encourage you to read the privacy policies of any other websites you visit before
            providing your information to them. The Vault and Marketplace will evolve over time, and
            this Privacy Policy will change to reflect that evolution. If we make changes, we will
            notify you by revising the date at the top of this Privacy Policy. In some cases, if we
            make significant changes, we may give you additional notice by adding a statement to our
            homepage. We encourage you to review this Privacy Policy periodically to stay informed
            about our practices.
          </Text>
          <Text>
            1.2 Some third-party providers may place cookies or pixels - small data files stored on
            your hard drive or in device memory - on your browser or hard drive. Note that this
            Privacy Policy does not cover the use of cookies or pixels by such third parties. Most
            web browsers are set to accept cookies and pixels by default, but you can usually set
            your browser to remove or reject browser cookies or pixels. If you do choose to remove
            or reject cookies or pixels, however, your ability to use the Vault or the Marketplace
            might be affected.
          </Text>
          <Text>1.3 This Privacy Policy should be read in conjunction with our Terms of Use.</Text>
          <Text>
            1.4 For accessing the Vault and the Marketplace you need to create an account (the
            "Marketplace Account"), with a user name and a password. The Marketplace Account also
            provides access to the Games. Your use of the Games and any personal information you
            provide through the Games remains subject to the terms of this Privacy Policy and our
            Terms of Use, as each may be updated from time to time. You authorize OL to share your
            personal information with the Developers in order to provide access to the Games. The
            use of the Games is governed by the respective terms of use and privacy policies.
          </Text>
          <Text>
            1.5 Any questions, comments or complaints that you might have should be emailed to
            support@openloot.com
          </Text>
          <Text fontWeight="bold" fontSize="xl" my={2}>
            2. HOW WE COLLECT YOUR PERSONAL DATA
          </Text>
          <Text>
            2.1 Your personal data may be collected in different ways. You may provide us with your
            data directly. This will generally happen when you:
          </Text>
          <Text>2.1.1 filling out a form.</Text>
          <Text>2.1.2 giving us written feedback.</Text>
          <Text>2.1.3 communicating with us via third party social media sites.</Text>
          <Text>2.1.4 communicating with us via the Site, the Vault, or the Marketplace.</Text>
          <Text>2.1.5 subscribing to our newsletters or notifications.</Text>
          <Text>2.1.6 creating an account with us.</Text>
          <Text>
            2.2 We can also collect your personal information automatically, using cookies and/or
            other similar tracking technologies. To learn more about cookies and similar
            technologies used, including how you can accept or refuse cookies, please see section 9
            below.
          </Text>
          <Text fontWeight="bold" fontSize="xl" my={2}>
            3. INFORMATION WE COLLECT
          </Text>
          <Text>The personal information we collect from you generally may include:</Text>
          <Text>
            3.1 Network information regarding transactions, including, among other things, the type
            of device you use, access times, hardware model, MAC address, IP address, operating
            system and version, and other unique device identifiers.
          </Text>
          <Text>
            3.2 Information about plugins you might be using, included but not limited to those
            related to the management of cryptocurrency assets and any information provided by them,
            and your blockchain public address.
          </Text>
          <Text>
            3.3 We may receive network information from you as a result of your use of the Vault or
            the Marketplace
          </Text>
          <Text>
            3.4 When you complete a form online or subscribe to our newsletters or notifications on
            the Site:
          </Text>
          <Text>3.4.1 name</Text>
          <Text>3.4.2 email address</Text>
          <Text>3.4.3 physical address</Text>
          <Text>3.4.4 zip code</Text>
          <Text>3.4.5 phone number/fax number</Text>
          <Text>3.4.6 age</Text>
          <Text>3.4.7 gender</Text>
          <Text>3.5 When you register as a user:</Text>
          <Text>
            3.5.1 For the creation of a Marketplace Account we may collect your name and password.
          </Text>
          <Text>
            3.5.2 For the Marketplace we may collect the following personal information from you:
          </Text>
          <Text>(a) If you are an individual:</Text>
          <Text>
            (i) Copy of a document that establishes your identity (e.g. Passport, Driver's License);
          </Text>
          <Text>(ii) Full legal name;</Text>
          <Text>(iii) Correct permanent address including postcode (if appropriate);</Text>
          <Text>(iv) Date and place of birth;</Text>
          <Text>(v) Nationality;</Text>
          <Text>(vi) Identification number;</Text>
          <Text>(vii) Occupation;</Text>
          <Text>(viii) Purpose/nature of the intended business if any;</Text>
          <Text>
            (ix) The source of funds (i.e., generated from a transaction or business);; and
          </Text>
          <Text>(x) A selfie</Text>
          <Text>(b) If you are an entity:</Text>
          <Text>The following information is required for all clients who are entities:</Text>
          <Text>(i) Full legal entity name;</Text>
          <Text>(ii) Doing business as ("DBA") name (if different);</Text>
          <Text>(iii) State and country of formation;</Text>
          <Text>(iv) Date of formation;</Text>
          <Text>(v) Tax ID / registration number;</Text>
          <Text>(vi) Number of employees;</Text>
          <Text>(vii) Link to business website;</Text>
          <Text>(viii) Nature of business;</Text>
          <Text>(ix) Physical business address;</Text>
          <Text>
            (x) Information about the authorized representative such as: (i) passport for personal
            information; and (ii) any document that evidence the incumbency of the representative;
          </Text>
          <Text>
            (xi) Information about each ultimate beneficial owner that directly or indirectly owns
            at least 20% of the entity such as: (i) passport; (ii) proof of address; and (iii)
            source of wealth declaration. Open Loot will also collect information related to the
            title of the beneficial owner's identity and his/her percentage of ownership if it is
            above 20 %.
          </Text>
          <Text>
            (xii) Personal information about all directors and at least member of Senior Management
            (e.g., Chief Executive Officer, Chief Operating Officer) of the company (such us Full
            Name, ID/Passport No., date of birth, address and information related to titles of
            director); and
          </Text>
          <Text>(xiii) Purpose of the account.</Text>
          <Text>
            Additional requirements may apply as per KYC requirements and the type of entity.
          </Text>
          <Text>
            3.6 For use of the Marketplace you will need to provide the following information, and
            depending on the method used to top up the Marketplace Account:
          </Text>
          <Text>3.6.1 Top-Up with Credit or Debit Card: Credit or Debit Card Details</Text>
          <Text>
            3.6.2 Top-Up by way of wire transfer: Bank account details for linking bank account with
            Marketplace Account.
          </Text>
          <Text>3.6.3 Top-Up with Cryptocurrencies: blockchain wallet identification</Text>
          <Text>
            We may also receive personal information from third parties, when you sign up to
            services provided by OL through a social network; in such occasions, we may receive the
            personal information you have made available to such third parties, and which you have
            allowed for them to relay via your privacy settings
          </Text>
          <Text>
            3.7 OL will keep a detail of your transaction history in the Marketplace (top-ups,
            transfers and withdrawals).
          </Text>
          <Text>
            3.8 OL may require you to provide additional information and documents at the request of
            any competent authority or as required under any applicable law or regulation, including
            laws related to anti- money laundering regulations. OL may also require you to provide
            additional information and documents in cases when it has reasons to believe that:
          </Text>
          <Text>
            3.8.1 Your Marketplace Account is being used for money laundering or any other illegal
            activity;
          </Text>
          <Text>
            3.8.2 You have concealed or provided false identification information and other details;
            or
          </Text>
          <Text>
            3.8.3 Transactions performed through your Marketplace Account have been made in breach
            of the Terms of Use.
          </Text>
          <Text>
            In these cases, at its sole discretion OL may suspend or terminate your Marketplace
            Account and any pending transactions in the Marketplace until you provide the additional
            information and documents which is sufficient to satisfy the requirements of applicable
            law, to the satisfaction of OL.
          </Text>
          <Text>3.9 Instant messaging usernames and social media handles.</Text>

          <Text fontWeight="bold" fontSize="xl" my={2}>
            4. THE PURPOSES FOR WHICH OL USES YOUR PERSONAL INFORMATION
          </Text>
          <Text>
            4.1 As with nearly all interactions that take place on the World Wide Web, our servers
            may receive information by virtue of your interaction with them, including but not
            limited to IP Addresses.
          </Text>
          <Text>
            4.2 Some of the services in the Marketplace and the Vault require permissions that could
            potentially be used to access additional personal information. Such permissions are used
            for an extremely limited technical purpose for allowing the Vault and Marketplace to
            properly interact with your browser. No additional information is obtained beyond what
            is necessary to provide the Vault or the Marketplace. No information received is shared
            with any third-party except those affiliated with OL as stated on section 1.4. above, or
            as required for provision of the Marketplace Account. This information can include, but
            is not limited to player email addresses, player name, and player address.
          </Text>
          <Text>
            4.3 Any identifying information collected via Segment.io is controlled by Segment.io,
            Inc. including but not limited to player email addresses, player name, and player
            address.
          </Text>
          <Text>
            4.4 Public blockchains provide transparency into transactions and OL is not responsible
            for preventing or managing information broadcasted on a blockchain.
          </Text>
          <Text>
            4.5 Personal information collected for the creation and use of the Marketplace Account
            is used to allow the interaction of the Marketplace Account with the different top-up
            and withdrawal methods, operate the Marketplace Account with our third-party providers
            and comply with applicable KYC requirements.
          </Text>
          <Text>
            4.6 OL will not sell your data, nor will ever sell your data without first giving you
            the opportunity to opt out of such sale.
          </Text>
          <Text>4.7 OL may use the information we collect in the following ways:</Text>
          <Text>
            4.7.1 To analyze trends for how the Site, the Vault or the Marketplace are being used;
          </Text>
          <Text>4.7.2 To improve the Site, Vault and Marketplace;</Text>
          <Text>
            4.7.3 To help personalize your experience of the Site, Vault and Marketplace; and
          </Text>
          <Text>
            4.7.4 If you signed up through a supported social network account, we (or the
            Developers) may share your accomplishments and goals achieved in the Games in the
            relevant social network
          </Text>
          <Text>
            4.7.5 If you gave us your contact information such as email address, we may use that
            information to contact you to send you technical notices, updates, confirmations,
            security alerts, to provide support to you, to tell you about other products and
            services that we think might interest you, or to respond to your comments or questions.
            In order to provide marketing information to Users, we may also share your email address
            with third parties. If you live in the EEA please refer to the marketing provision in
            section 7 below.
          </Text>
          <Text>
            4.7.6 To comply with applicable KYC requirements and OL's Bank Secrecy Act / Anti-Money
            Laundering and OFAC Compliance Program Manual.
          </Text>
          <Text>4.7.7 To monitor web traffic.</Text>
          <Text>
            4.7.8 Granting you access to our Discord channels and general management of our Discord
            channels and other social media accounts.
          </Text>
          <Text>
            4.8 The legal basis that we rely on for processing your personal data is one of the
            following:
          </Text>
          <Text>4.8.1 to perform in terms of a contract where you are a party.</Text>
          <Text>
            4.8.2 a legitimate business interest (that is not outweighed by interests you have to
            protect your data).
          </Text>
          <Text>4.8.3 consent (which we will ask for before we process your data).</Text>
          <Text>4.8.4 to comply with a legal obligation.</Text>
          <Heading fontWeight="bold" fontSize="xl" my={2}>
            5. WHO WE SHARE YOUR PERSONAL INFORMATION WITH AND FOR WHAT PURPOSES
          </Heading>
          <Text>
            5.1 OL may share the information we collect with third parties who need to access it in
            order to process and perform work on our behalf, including doing things like helping us
            make the Site, Vault or Marketplace available, including without limitation payment
            services providers (such as Circle, Moonpay or Ramp), anti-fraud services providers
            (such as Jumio and Sumsub), providing analytics services for us (such as Amplitude) or
            third party platforms for retargeting advertising campaigns (such as Google or
            Facebook). We work hard to ensure that these third parties only access and use your
            information as necessary to perform their functions.
          </Text>
          <Text>
            5.2 OL may create aggregations and anonymizations that contain your information in a way
            that does not directly identify you. OL may use and/or share those aggregations and
            anonymizations for a variety of purposes related to the Site, the Vault or the
            Marketplace, or our company and its business.
          </Text>
          <Text>
            5.3 OL may disclose your personal information to our subsidiaries, affiliated companies,
            agents, businesses, or service providers who process your personal information on our
            behalf in providing the Site, the Vault or the Marketplace to you. Our agreements with
            these service providers limit the kinds of information they can use or process and
            ensure they use reasonable efforts to keep your personal information secure.
          </Text>
          <Text>
            5.4 OL may disclose your personal information to Developers, so you may receive updates,
            and other offerings regarding the Games or the Digital Assets. Our agreements with these
            service providers limit the kinds of information they can use or process and ensure they
            use reasonable efforts to keep your personal information secure.{' '}
          </Text>
          <Text>
            5.5. OL also reserves the right to disclose personal information that OL believes, in
            good faith, is appropriate or necessary to enforce our Terms of Use, take precautions
            against liability or harm, to investigate and respond to third-party claims or
            allegations, to respond to court orders or official requests, to protect the security or
            integrity of the Vault and the Marketplace, and to protect the rights, property, or
            safety of OL, our users or others.
          </Text>
          <Text>
            5.6 OL reserves the right to transfer your information in the event that OL is involved
            in a merger, acquisition, joint venture, sale, bankruptcy, insolvency, reorganization,
            receivership, assignment for the benefit of creditors, or the application of laws or
            equitable principles affecting creditors' rights generally, or other change of control.
            If such event happens, OL may disclose your information to another entity related to
            such event.
          </Text>
          <Text>
            5.7 The personal data we collect may be also transferred to and stored in countries
            outside of the jurisdiction you are in where we and our third-party service providers
            have operations. In the event of such transfer, we ensure that OL will use reasonable
            effort to ensure that any transferred information is treated in a manner consistent with
            this Privacy Policy. Where you reside in the EEA, and we transfer your personal
            information outside the EEA, please see the provisions in section 6 below.
          </Text>

          <Heading fontWeight="bold" fontSize="xl" my={2}>
            6. DATA TRANSFERS
          </Heading>
          <Text>
            If your information is collected within the European Economic Area (“EEA”), your
            information may be transferred outside of the EEA. Where we transfer your personal data
            outside the EEA we ensure that appropriate safeguards are in place by using appropriate
            data transfer mechanisms such as the European Commission adequacy decision or the EU
            Standard Contractual Clauses.
          </Text>
          <Heading fontWeight="bold" fontSize="xl" my={2}>
            7. MARKETING
          </Heading>
          <Text>
            7.1 We strive to provide you with choices regarding certain personal data uses,
            particularly around marketing and advertising.
          </Text>
          <Text>7.1.1 Promotional offers from us</Text>
          <Text>
            (a) We may use your personal data to form a view on what we think you may want or need,
            or what may be of interest to you. This is how we decide which products, services and
            offers may be relevant for you (we call this marketing).
          </Text>
          <Text>
            7.1.2 You will receive marketing communications from us if you have requested
            information from us or purchased goods or services from us and you have not opted out of
            receiving that marketing.
          </Text>
          <Text>7.1.3 Third-party marketing</Text>
          <Text>
            (a) We will get your express opt-in consent before we share your personal data with any
            third party for marketing purposes.
          </Text>
          <Heading fontWeight="bold" fontSize="xl" my={2}>
            8. YOUR CHOICE
          </Heading>
          <Text>
            OL will process your personal information in accordance with this Privacy Policy, and as
            part of that you will have limited or no opportunity to otherwise modify how your
            information is used by OL.
          </Text>
          <Text>
            Should you reside in the EEA, this section 8 will not apply to you. Please see section
            13 below which sets out your privacy rights.
          </Text>
          <Heading fontWeight="bold" fontSize="xl" my={2} id="section-9">
            9. COOKIES
          </Heading>
          <Text>
            Our Site uses "Cookies", which are text files placed on your computer which help OL
            analyze how users use the Site, and similar technologies (e.g. web beacons, pixels, ad
            tags and device identifiers) to recognize you and/or your device on, off and across
            different devices and our Site, as well as to improve the Site, to improve marketing,
            analytics or functionality. The use of Cookies is standard on the internet. Although
            most web browsers automatically accept cookies, the decision of whether to accept or not
            is yours. You may adjust your browser settings to prevent the use of cookies, or to
            provide notification whenever a cookie is sent to you. You may refuse the use of cookies
            by selecting the appropriate settings on your browser. However, please note that if you
            do this, you may not be able to access the full functionality of our Site.
          </Text>
          <Heading fontWeight="bold" fontSize="xl" my={2}>
            10. CHILDREN'S PERSONAL INFORMATION
          </Heading>
          <Text>
            The Games and therefore the Marketplace Account are not intended for children under 13,
            and OL do not knowingly solicit or collect personal information from children under the
            age of 16. If we become aware that we have collected personal information from a child
            under 16, we will promptly delete that information. If you believe a child under such
            age may have provided us with personal information, please contact us using the contact
            details set out at the end of this Privacy Policy.
          </Text>
          <Heading fontWeight="bold" fontSize="xl" my={2}>
            11. INFORMATION SECURITY AND DATA STORAGE
          </Heading>
          <Text>
            11.1 Whilst neither we, nor any other organization, can guarantee the security of
            information processed online, we do have appropriate security measures in place to
            protect your personal information. For example, we store the personal information you
            provide on computer systems with limited access, encryption, or both. The primary
            objective of OL's privacy procedures is to ensure the security and confidentiality of
            customer information.
          </Text>
          <Text>
            11.2 All personal data that OL internally collects will be stored by OL's cloud hosting
            provider in the United States. Any data stored in the cloud will be stored in secure
            servers to ensure that your data is protected.
          </Text>
          <Text>
            11.3 All information related to credit cards, debit cards, bank accounts and wallets is
            stored by our payment service providers, Circle, Moonpay, Ramp and/or any other vendor
            that OL may contract in the future
          </Text>
          <Text>
            11.4 All information relating to KYC processes is stored by our KYC services providers,
            Jumio, Sumsub and/or whichever vendor the Company might currently have
          </Text>
          <Text>
            11.5 All information relating to analyzing our customers' preferences, in order to serve
            personalized content to you, is stored by our analytic service provided, Amplitude,
            and/or any other vendor that OL may contract in the future.
          </Text>
          <Heading fontWeight="bold" fontSize="xl" my={2}>
            12. HOW LONG WILL YOU USE MY PERSONAL DATA FOR?
          </Heading>
          <Text>
            12.1 We will only retain your personal data for as long as reasonably necessary to
            fulfil the purposes we collected it for, including for the purposes of satisfying any
            legal, regulatory, tax, accounting or reporting requirements. We may retain your
            personal data for a longer period in the event of a complaint or if we reasonably
            believe there is a prospect of litigation in respect to our relationship with you.
          </Text>
          <Text>
            12.2 To determine the appropriate retention period for personal data, we consider the
            amount, nature and sensitivity of the personal data, the potential risk of harm from
            unauthorised use or disclosure of your personal data, the purposes for which we process
            your personal data and whether we can achieve those purposes through other means, and
            the applicable legal, regulatory, tax, accounting or other requirements.
          </Text>
          <Heading fontWeight="bold" fontSize="xl" my={2}>
            13. PRIVACY RIGHTS
          </Heading>
          <Text>
            13.1 Subject to applicable law, you may have some or all of the following rights in
            respect of your personal information:
          </Text>
          <Text>
            13.1.1 to obtain a copy of your personal information together with information about how
            and on what basis that personal information is processed;
          </Text>
          <Text>
            13.1.2 to rectify inaccurate personal information (including the right to have
            incomplete personal information completed);
          </Text>
          <Text>
            13.1.3 to erase your personal information (in limited circumstances, where it is no
            longer necessary in relation to the purposes for which it was collected or processed);
          </Text>
          <Text>13.1.4 to restrict processing of your personal information where:</Text>
          <Text>(a) the accuracy of the personal information is contested;</Text>
          <Text>
            (b) the processing is unlawful but you object to the erasure of the personal
            information; or
          </Text>
          <Text>
            (c) we no longer require the personal information but it is still required for the
            establishment, exercise or defense of a legal claim;
          </Text>
          <Text>
            13.1.5 to challenge processing which we have justified on the basis of a legitimate
            interest (as opposed to your consent, or to perform a contract with you);
          </Text>
          <Text>13.1.6 to prevent us from sending you direct marketing;</Text>
          <Text>
            13.1.7 to withdraw your consent to our processing of your personal information (where
            that processing is based on your consent);
          </Text>
          <Text>
            13.1.8 to object to decisions which are based solely on automated processing or
            profiling;
          </Text>
          <Text>
            13.1.9 in addition to the above, you have the right to file a complaint with the
            supervisory authority.
          </Text>
          <Text>
            13.2 If you reside in the EEA and you wish to exercise any of your rights set out above,
            please contact us at: support@openloot.com
          </Text>
          <Text>
            13.3 If you reside in California, you may request certain general information regarding
            our disclosure of personal information to third parties for their direct marketing
            purposes.
          </Text>
          <Heading fontWeight="bold" fontSize="xl" my={2}>
            14. CHANGES AND UPDATES
          </Heading>
          <Text>
            14.1 This Privacy Policy may be revised periodically and this will be reflected by the
            "Last update posted" date above. Please revisit this page to stay aware of any changes.
            Your continued use of the Vault and the Marketplace constitutes your agreement to this
            Privacy Policy and any future revisions.
          </Text>
          <Text>
            14.2 If you have any questions in relation to this Privacy Policy please contact us at:
            support@openloot.com
          </Text>
        </Box>
      </Box>
      <Flex
        mt={5}
        direction="column"
        width="100%"
        height="100%"
        justifyContent="flex-end"
        borderTop="1px"
        borderColor="seprator"
      >
        <Link
          href="/"
          alignSelf="flex-end"
          display="inline-flex"
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Button mt="6" variant="light-outline" cursor="pointer">
            Back
          </Button>
        </Link>
      </Flex>
    </Container>
  </>
)
