import Head from 'next/head'
import { Box, Divider, Flex, HStack, Image, Text, useToast, VStack } from '@chakra-ui/react'
import { FaXTwitter } from 'react-icons/fa6'
import { useState } from 'react'
import Link from 'next/link'
import FourOhFour from 'pages/404'
import { formatHtmlTitle } from '../../utils/globals'
import { Breadcrumb, Button, Span } from '../../components/atoms'
import { AUTH_API_URL } from '../../constants'
import { useUser } from '../../hooks/useUser'
import { useLinkedAccounts } from '../../hooks/useLinkedAccounts'
import { LinkToTwitter } from '../../components/organisms/points/LinkToTwitter'
import { RequireUser } from '../../components/templates/account'
import { useSignUpForExternalGameMutation } from '../../hooks/externalGames/useSignUpForExternalGame'
import { useIsUserSignedUpForExternalGame } from '../../hooks/externalGames/useIsUserSignedUpForExternalGame'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { Footer, Navbar } from '../../components/templates/layout-components'
import { Login2FAModal } from '../../components/organisms/auth/Login2FAModal'
import { CookieConsentBanner } from '../../components/organisms/CookieConsentBanner'
import { AddCountryAndDobModal } from '../../components/organisms/AddCountryAndDob'
import { useIsLoggedIn } from '../../hooks/useIsLoggedIn'

const branding = `Billionaires Club` // this is the string we have to update
const gameName = 'Game Name'
const gameDeveloper = 'Game Developer'
const slug = 'billionaires'
const enabled = true

const BillionairesClubPage = () => {
  const isLoggedIn = useIsLoggedIn()

  const [shouldLoadUser, setShouldLoadUser] = useState(false)
  const links = [{ href: '/#', label: `RSVP for ${branding}` }]
  const { data: user, isLoading: isLoadingUser } = useUser({ enabled: isLoggedIn })
  const { data: linkedAccounts } = useLinkedAccounts(user)

  let connectedToTwitter = false

  if (user && linkedAccounts) {
    const twitterAccount = linkedAccounts.find((it) => it.provider === 'twitter')
    if (twitterAccount && twitterAccount.externalAccount) connectedToTwitter = true
  }

  const accountsToFollow = ['@OpenLoot', '@playbigtime', '@PepitoTheCat']

  const [followedAccounts, setFollowedAccounts] = useLocalStorage(
    'billionaires-checks',
    [] as string[]
  )

  const { mutate, isLoading: isLoadingSignup } = useSignUpForExternalGameMutation()
  const { data: signedResponse } = useIsUserSignedUpForExternalGame(slug, {
    enabled: Boolean(user),
  })
  const [requireLogin, setRequireLogin] = useState(false)

  const toast = useToast()

  const rsvpToClub = () => {
    mutate(
      { slug },
      {
        onError: (error) => {
          toast({
            title: 'There was an error',
            description: 'Please contact customer support',
            status: 'error',
          })
        },
      }
    )
  }

  const signIn = async (provider: string) => {
    const has2FAEnabled =
      user?.totpVerified === 'verified' || user?.phoneNumberStatus === 'validated'

    if (!has2FAEnabled) {
      toast({
        status: 'error',
        title: 'Two-Factor Authentication (2FA) is required to link multiple accounts.',
      })
      return
    }
    window.open(`${AUTH_API_URL}/v2/auth/link/${provider}?returnTo=/billionairesclub`, '_self')
  }

  const rsvpEnabled = followedAccounts.length === accountsToFollow.length
  const hasRsvped = signedResponse?.signedUp

  if (hasRsvped && accountsToFollow.length !== followedAccounts.length) {
    setFollowedAccounts(accountsToFollow)
  }

  const connect = (
    <Flex mx={{ base: 0, md: '21px' }} w="full" justifyContent="center">
      {connectedToTwitter ? (
        <Button
          px={{ base: 0, md: '76px' }}
          py="12px"
          w={{ base: '100%', md: 'initial' }}
          size="full"
          variant="outline"
          borderRadius="6px"
          color="white"
          fontSize={{ base: '18px', md: '22px' }}
          leftIcon={<FaXTwitter color="#FFF" size="20px" />}
          rightIcon={
            <Image
              src="/images/icons/check.svg"
              w="24px"
              filter=" brightness(0) saturate(100%) invert(82%) sepia(9%) saturate(2037%) hue-rotate(97deg) brightness(80%) contrast(86%)"
            />
          }
        >
          <span> Connected to X</span>
        </Button>
      ) : (
        <Button
          px="76px"
          py="12px"
          size="full"
          variant="outline"
          color="gray.500"
          onClick={() => signIn('twitter')}
          loadingText={<span>Loading...</span>}
          leftIcon={<FaXTwitter color="#FFF" size="20px" />}
        >
          <span> Connect with X</span>
        </Button>
      )}
    </Flex>
  )
  const follow = (
    <Flex px={{ base: 0, md: '65px' }} w="full" justifyContent="center">
      {connectedToTwitter ? (
        <Flex direction="column" w="full" justifyContent="center" alignItems="center">
          <Button
            variant="light"
            bgColor="gray.300"
            borderRadius="22px"
            px="46px"
            py="14.5px"
            minW="312px"
          >
            <Text fontFamily="Montserrat" fontSize="20px" color="black" fontWeight={400}>
              <Span
                fontFamily="Montserrat"
                fontSize="20px"
                color="black"
                fontWeight={700}
              >{`${followedAccounts.length}/${accountsToFollow.length}`}</Span>{' '}
              accounts followed
            </Text>
          </Button>

          <Flex direction="column" justifyContent="center" alignItems="center" w="full" mt="14px">
            {accountsToFollow.map((it) => (
              <Flex direction="row" key={`follow-${it}`} mt="10px">
                <LinkToTwitter
                  content={it}
                  follow
                  bold
                  onClick={() => {
                    setTimeout(() => {
                      if (!followedAccounts.includes(it) && it) {
                        setFollowedAccounts([...followedAccounts, it])
                      }
                    }, 1000)
                  }}
                />
                <Image
                  ml="10px"
                  src="/images/icons/check.svg"
                  display={followedAccounts.includes(it) ? 'flex' : 'none'}
                  w="10px"
                  filter=" brightness(0) saturate(100%) invert(82%) sepia(9%) saturate(2037%) hue-rotate(97deg) brightness(80%) contrast(86%)"
                />
              </Flex>
            ))}
          </Flex>
        </Flex>
      ) : (
        <Button
          variant="light"
          color="black"
          fontSize="20px"
          fontFamily="Montserrat"
          fontWeight={400}
          bgColor="gray.300"
          borderRadius="22px"
          px="65px"
          size="full"
          py="14.5px"
        >
          <span>Follow X accounts</span>
        </Button>
      )}
    </Flex>
  )
  const rsvp = (
    <Flex w="full" justifyContent="center">
      {hasRsvped ? (
        <Flex direction="row" alignItems="center">
          <Text
            color="state.success"
            fontFamily="Montserrat"
            fontWeight={700}
            fontSize={{ base: '22px', md: '30px' }}
          >
            Success!
          </Text>
          <Box
            ml={{ base: '24px', md: '35px' }}
            borderRadius="9999px"
            border="3px solid var(--Success, #54BC8C)"
            p={{ base: '7px', md: '10px' }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            w={{ base: '38px', md: '44px' }}
            h={{ base: '38px', md: '44px' }}
          >
            <Image
              src="/images/icons/check.svg"
              w="24px"
              h="18px"
              filter="brightness(0) saturate(100%) invert(82%) sepia(9%) saturate(2037%) hue-rotate(97deg) brightness(80%) contrast(86%)"
            />
          </Box>
        </Flex>
      ) : (
        <Button
          px={{ base: 0, md: '168px' }}
          w={{ base: '100%', md: 'initial' }}
          py="12px"
          fontSize={{ base: '18px', md: '22px' }}
          size="full"
          isLoading={isLoadingSignup}
          variant={rsvpEnabled ? 'light' : 'none'}
          bgColor={rsvpEnabled ? 'white' : 'gray.800'}
          color={rsvpEnabled ? 'black' : 'gray.400'}
          onClick={() => {
            if (rsvpEnabled && user) {
              rsvpToClub()
            } else {
              setRequireLogin(true)
            }
          }}
        >
          <span> RSVP</span>
        </Button>
      )}
    </Flex>
  )

  const requirements = [connect, follow, rsvp]

  const content = (
    <>
      <Head>
        <title key="title">{formatHtmlTitle(`RSVP for ${branding}`)}</title>
      </Head>

      <VStack position="relative" justifyContent="flex-start" w="full">
        <Image
          position="absolute"
          src="/images/billionaires-background.png"
          alt="Background Image"
          minH="650px"
          objectFit="cover"
          zIndex={-1}
        />
        <HStack
          minW={{ base: '100%', lg: '1362px' }}
          px={{ base: '10px', md: 0 }}
          flexDir={{ base: 'column', md: 'row' }}
        >
          <Box w="full" display="flex" flexDir="column" justifyContent="flex-start">
            <Breadcrumb items={links} borderColor="whiteAlpha.400" />
            <Divider
              display={{ base: 'none', md: 'block' }}
              mb={6}
              borderColor="rgba(242, 242, 242, 0.20)"
              w="full"
            />
          </Box>
        </HStack>

        <Flex
          mx="auto"
          maxW={{ base: '100%', lg: '1362px' }}
          px={{ base: '12px', md: 0 }}
          alignItems="flex-start"
          direction="column"
        >
          <Flex alignItems="center" direction="column" w="full">
            <Image
              mt={{ base: '24px', md: '75px' }}
              src="/images/icons/points.svg"
              filter="drop-shadow(4px 6px 8px rgba(0, 0, 0, 0.35))"
              width={{ base: '103.727px', md: '189.774px' }}
              height={{ base: '103.727px', md: '189.774px' }}
              flexShrink={0}
            />
            <Text
              mt="30px"
              fontFamily="Montserrat"
              fontSize={{ base: '30px', md: '50px' }}
              fontWeight="700"
              lineHeight="55px"
            >
              {gameName}
            </Text>

            <Text fontFamily="Montserrat" fontSize={{ base: '20px', md: '24px' }}>
              {gameDeveloper}
            </Text>

            <Box
              mt="62px"
              w="auto"
              flexGrow={1.31}
              bgColor="rgba(255, 255, 255, 0.10)"
              p="24px"
              mb={{ base: user ? '0' : '0', md: '0' }}
            >
              <Text fontSize={{ base: '20px', md: '20px' }} fontWeight="700">
                About this Game
              </Text>

              <Text mt="10px">
                A dynamic 3D board game where rolling dice advances you along a path of
                opportunities. Land on squares to start businesses, investments, or trigger random
                events. As businesses grow, revenue meters fill, ready for collection. Strategize on
                what to invest in, upgrade, or sell while avoiding stock market crashes and
                lifestyle expenses. Engage friends, earn networking points, and enjoy humorous
                events. Game-specific NFTs aid your journey, with new NFTs unlocked as you grow your
                virtual wealth.
              </Text>

              <Text mt="20px">
                RSVP to be one of the first to be able to purchase{' '}
                <Span fontWeight={700}>{branding}</Span> NFTs!
              </Text>
            </Box>

            <Text
              mt="35px"
              display={{ base: user ? 'flex' : 'none', md: 'flex' }}
              fontFamily="Montserrat"
              fontWeight="700"
              fontSize={{ base: '24px', md: '24px' }}
            >
              Requirements
            </Text>

            <Divider
              my="24px"
              borderColor="rgba(224, 224, 224, 0.20)"
              display={{ base: user ? 'flex' : 'none', md: 'flex' }}
            />

            {!user && (
              <Flex
                w="full"
                justifyContent="center"
                mb="70px"
                display={{ base: 'none', md: 'flex' }}
              >
                <Button
                  px={{ base: 0, md: '39px' }}
                  w={{ base: '100%', md: 'initial' }}
                  py="16px"
                  fontSize={{ base: '18px', md: '30px' }}
                  size="full"
                  variant="light"
                  onClick={() => {
                    setShouldLoadUser(true)
                  }}
                >
                  <span> Sign in to Open Loot</span>
                </Button>
              </Flex>
            )}

            {user && (
              <HStack
                flexDir={{ base: 'column', md: 'row' }}
                w="full"
                spacing={{ base: 0, md: '35px' }}
                mb="70px"
                alignItems="flex-start"
                justifyContent="center"
              >
                {requirements.map((req, idx) => (
                  <Flex
                    key={`req-${idx}`}
                    w="full"
                    direction="column"
                    alignItems="center"
                    justifyContent="flex-start"
                    pb={{ base: '35px', md: 0 }}
                  >
                    <Box
                      w="49px"
                      p="10px"
                      border="1px solid var(--Modal-Stroke, rgba(255, 255, 255, 0.40))"
                      borderRadius="30px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Text
                        fontFamily="Montserrat"
                        fontWeight={700}
                        fontSize={{ base: '24px', md: '24px' }}
                      >
                        {idx + 1}
                      </Text>
                    </Box>
                    <Flex mt={{ base: '12px', md: '45px' }} textAlign="center" w="full">
                      {req}
                    </Flex>
                  </Flex>
                ))}
              </HStack>
            )}
            <Divider
              display={{ base: 'block', md: 'block' }}
              mb={6}
              borderColor="rgba(224, 224, 224, 0.20)"
              w="full"
            />
            <Text fontSize="12px" mb={{ base: '70px', md: '19px' }} color="gray.300">
              Purus Damnificatio Nibh at aut memoriam, circumspecte, labefactari ea odio ea Indago M
              Error, Rem Eorum Facundissima ad eos ut dicta temporibus utilitatem ea laudantium.
              Sunc hac topem viridi nec vel "Harum" quod, lacus rem materias porro d nostris
              deturbare uidem eum ea moiioculnn ac decessu protegere at tot porta.
            </Text>
          </Flex>
        </Flex>
      </VStack>
      <Flex
        display={{ base: 'flex', md: 'none' }}
        sx={{ position: 'fixed', bottom: '0' }}
        zIndex="sticky"
        justifyContent="space-between"
        flexDirection="row"
        backgroundColor="rgba(0,0,0,0.9)"
        width="100%"
        py="12px"
      >
        <Link href="/" legacyBehavior>
          <Button
            height="44px"
            flexGrow={user ? 1 : 1}
            flexBasis={user ? '100%' : '33%'}
            ml="10px"
            mr="12px"
            variant="light-outline"
            fontSize="16px"
            lineHeight="24px"
            fontWeight="700"
            borderWidth="1px"
            py="4px"
          >
            Back
          </Button>
        </Link>

        {!user && !isLoadingUser && (
          <Button
            height="44px"
            flexGrow={2}
            flexBasis="66%"
            ml="10px"
            mr="12px"
            variant="light"
            fontSize="16px"
            lineHeight="24px"
            fontWeight="700"
            borderWidth="1px"
            py="4px"
            onClick={() => {
              setShouldLoadUser(true)
            }}
          >
            Sign in to Open Loot
          </Button>
        )}
      </Flex>
    </>
  )

  if (!user && shouldLoadUser) {
    return <RequireUser>{content}</RequireUser>
  }

  if (user) {
    return (
      <Flex direction="column" minH="100vh">
        <Navbar />
        <Flex as="main" flexGrow={1} direction="column">
          {content}
        </Flex>
        <Login2FAModal />
        <CookieConsentBanner />
        <AddCountryAndDobModal />
        <Footer />
      </Flex>
    )
  }
  return content
}

const BillionairesClubPageWrapper = () => {
  if (!enabled) {
    return <FourOhFour />
  }
  return <BillionairesClubPage />
}

export default BillionairesClubPageWrapper
