import { useFeatures } from 'hooks/useFeature'
import { features } from 'types/vault/Features'

interface Props {
  children: JSX.Element
  feature: features
}

export const WithFeatureFlag = ({ children, feature }: Props) => {
  const { data } = useFeatures()
  const hasFeature = data?.features[feature] ?? false
  if (hasFeature) {
    return children
  }

  return null
}
