import Link from 'next/link'
import { Menu, MenuButton, MenuList, Box, Text, Image, HStack } from '@chakra-ui/react'
import { Button } from 'components/atoms'
import { ArrowDown } from 'components/atoms/Icons/ArrowDown'
import { User } from 'types/vault/User'
import { useLogoutMutation } from 'hooks/useLogoutMutation'
import { usePartnerPortal } from 'hooks/usePartnerPortal'

interface PartnerPortalNavbarProps {
  user?: User | null
}

const getUserAccessLevelStr = ({
  isOlAdmin,
  isTeamAdmin,
  isTeamMember,
}: {
  isOlAdmin: boolean
  isTeamAdmin: boolean
  isTeamMember: boolean
}) => {
  if (isOlAdmin) return 'OL Admin'
  if (isTeamAdmin) return 'Team Admin'
  if (isTeamMember) return 'Team Member'
  return ''
}

export const PartnerPortalNavbar: React.FC<PartnerPortalNavbarProps> = ({ user }) => {
  const { mutate } = useLogoutMutation()
  const onLogout = () => mutate()
  const { isOlAdmin, isTeamAdmin, isTeamMember } = usePartnerPortal()

  return (
    <HStack
      pl={{ base: 4, md: '30px' }}
      pr={{ base: 4, md: '35px' }}
      justifyContent="space-between"
      alignItems="center"
      h="105px"
      flexShrink={0}
    >
      <Link href="/" passHref legacyBehavior>
        <Image
          height={{ base: '40px', md: '45px' }}
          src="/images/open-loot-partner-portal-logo.svg"
          alt="open loot logo"
          cursor="pointer"
        />
      </Link>
      {user && (
        <Box pos="relative">
          <HStack spacing={6}>
            <Box textAlign="center">
              <Text color="gray.500" size="sm" lineHeight="30px">
                Access Level
              </Text>
              <Text fontWeight="bold" size="sm">
                {getUserAccessLevelStr({ isOlAdmin, isTeamAdmin, isTeamMember })}
              </Text>
            </Box>
            <Box h="45px" w="1px" bg="whiteAlpha.400" />
            <Box>
              <Text color="gray.500" size="sm" lineHeight="30px">
                Login as
              </Text>
              <Text fontWeight="bold" size="sm" maxW="200px">
                {user?.email}
              </Text>
            </Box>
          </HStack>
          <Menu>
            {() => (
              <>
                <MenuButton top={0} right={0} w={5} h={5} pos="absolute">
                  <ArrowDown />
                </MenuButton>
                <MenuList bg="white" color="menu-content" border="none" borderRadius="none">
                  <Button variant="unstyled" size="full" onClick={onLogout}>
                    Logout
                  </Button>
                </MenuList>
              </>
            )}
          </Menu>
        </Box>
      )}
    </HStack>
  )
}
