import { Text, VStack } from '@chakra-ui/react'
import { Checkbox, Accordian } from 'components/atoms'
import { useFilterMenu } from 'hooks/useFilterMenu'

export const InUseItemsFilter: React.FC = () => {
  const { inUseItemsFilter, handleInUseItemsFilterChange } = useFilterMenu()

  return (
    <Accordian label="In-Use Status" accordionPanelProps={{ px: 2.5, pt: 3, pb: 0 }}>
      <VStack spacing={2.5} alignItems="stretch">
        <Checkbox
          type="label-check"
          isChecked={inUseItemsFilter === 'in-use'}
          onChange={() => handleInUseItemsFilterChange('in-use')}
        >
          <Text
            color="white"
            fontSize="14px"
            fontWeight="400"
            lineHeight="1.25rem"
            _hover={{ fontSize: '15px', fontWeight: '600', transition: 'all .5s' }}
          >
            In-Use
          </Text>
        </Checkbox>

        <Checkbox
          type="label-check"
          isChecked={inUseItemsFilter === 'not-in-use'}
          onChange={() => handleInUseItemsFilterChange('not-in-use')}
        >
          <Text
            color="white"
            fontSize="14px"
            fontWeight="400"
            lineHeight="1.25rem"
            _hover={{ fontSize: '15px', fontWeight: '600', transition: 'all .5s' }}
          >
            Not In-Use
          </Text>
        </Checkbox>
      </VStack>
    </Accordian>
  )
}
