import { Box, Flex } from '@chakra-ui/react'
import { ArrowDown } from 'components/atoms/Icons/ArrowDown'
import { cloneElement, ReactNode, useEffect, useRef, useState } from 'react'

interface SelectOption extends Record<any, any> {
  label: ReactNode
  id: string
}

interface FilterOption extends Record<any, any> {
  label: ReactNode
  id: string
}

interface CustomSelectProps {
  filter?: FilterOption
  options: SelectOption[]
  defaultSelectedIndex?: number
  position: 'right' | 'left'
  onSelect?: (option: SelectOption) => void
}
export const CustomSelect = ({
  filter,
  options,
  position,
  defaultSelectedIndex,
  onSelect,
}: CustomSelectProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedKey, setSelectedKey] = useState(
    defaultSelectedIndex && defaultSelectedIndex >= 0 ? defaultSelectedIndex : 0
  )
  const ref = useRef<HTMLDivElement>(null)

  const handleSelect = (key: number) => {
    setSelectedKey(key)
    setIsOpen(false)

    onSelect?.(options[key])
  }

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // Ensure the selected option is always valid
  useEffect(() => {
    if (defaultSelectedIndex !== undefined && defaultSelectedIndex !== selectedKey) {
      setSelectedKey(defaultSelectedIndex)
    }
  }, [defaultSelectedIndex, setSelectedKey, selectedKey])

  return (
    <Box position="relative" ref={ref}>
      <Flex justify="space-between" flex={1} align="center" onClick={() => setIsOpen(!isOpen)}>
        {options[selectedKey]?.label &&
          cloneElement(options[selectedKey].label as React.ReactElement, { selected: true })}
        <Flex ml={3} align="center" cursor="pointer">
          <ArrowDown width="10px" />
        </Flex>
      </Flex>
      {isOpen && (
        <Box
          position="absolute"
          bg="white"
          top="100%"
          left={{
            base: `-${(ref.current?.offsetLeft ?? 0) - 4}px`,
            md: position === 'right' ? '-100px' : '-12px',
          }}
          right={{ base: `4px`, md: '-12px' }}
          px={3}
          maxHeight="200px"
          minWidth={{ base: '100px', md: '200px' }}
          width={{ base: 'calc(100vw - 8px)', md: 'auto' }}
          overflowY="auto"
          zIndex="1000"
          mt={1}
          borderRadius="6px"
        >
          {filter && (
            <Box py={1} cursor="default" borderColor="blackAlpha.200" key={filter.id}>
              {cloneElement(filter.label as React.ReactElement, {
                selected: false,
              })}
            </Box>
          )}
          {options.map((option, index) => (
            <Box
              py={1}
              cursor="pointer"
              borderBottom={index === options.length - 1 ? undefined : '1px solid'}
              borderColor="blackAlpha.200"
              key={index}
              onClick={() => handleSelect(index)}
            >
              {cloneElement(option.label as React.ReactElement, {
                selected: false,
              })}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}
