import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Image,
} from '@chakra-ui/react'
import Link from 'next/link'

export type BreadcrumbLinkItem = {
  label: string
  href: string
}

interface BreadcrumbProps {
  items: BreadcrumbLinkItem[]
  borderColor?: string
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ items, borderColor }) => (
  <ChakraBreadcrumb
    h="38px"
    spacing="11px"
    mt={{ base: 5, md: 0 }}
    alignItems="center"
    separator={<Image src="/images/icons/chevron-right.svg" alt="chevron right icon" />}
    fontSize="sm"
    lineHeight={5}
    borderBottom="1px"
    borderColor={borderColor || 'seprator'}
    sx={{
      ol: {
        height: '100%',
      },
      '.chakra-breadcrumb__list': {
        display: 'grid',
        gridTemplateColumns: `repeat(${items.length + 1}, auto)`,
        justifyContent: 'flex-start',
      },
      // ellipse overflowing text
      'li, li a, li span': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      // seprator should not shrink
      'span[role=presentation]': {
        flexShrink: 0,
      },
    }}
  >
    <BreadcrumbItem>
      <Link href="/" passHref legacyBehavior>
        <BreadcrumbLink>
          <Image src="/images/icons/home.svg" alt="home icon" />
        </BreadcrumbLink>
      </Link>
    </BreadcrumbItem>

    {items.map(({ label, href }, i) => {
      const isLastItem = i === items.length - 1

      return (
        <BreadcrumbItem
          key={label}
          color={isLastItem ? 'whiteAlpha.500' : 'white'}
          isCurrentPage={isLastItem}
        >
          {isLastItem ? (
            <span>{label}</span>
          ) : (
            <Link href={href} passHref legacyBehavior>
              <BreadcrumbLink>{label}</BreadcrumbLink>
            </Link>
          )}
        </BreadcrumbItem>
      )
    })}
  </ChakraBreadcrumb>
)
