import {
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { BottomAction } from 'components/atoms'
import { useToast } from 'hooks/useToast'
import { useEmailVerificationSetupMutation } from 'hooks/useEmailVerificationSetupMutation'
import { TwoFactorModalContent } from './TwoFactorModalContent'

export interface EmailSetupModalProps {
  onClose: () => void
  onSuccess: () => void
  skippable?: boolean
  isOpen: boolean
}

function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

const emailSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .test('is-valid', 'Invalid email', (value) => isValidEmail(value ?? '')),
  phoneDuplicated: Yup.bool().oneOf([false], 'That email address is already being used.'),
})

export const EmailSetupModal = ({
  onSuccess,
  onClose,
  skippable,
  isOpen,
}: EmailSetupModalProps) => {
  const { mutate: mutateSetup } = useEmailVerificationSetupMutation()

  const toast = useToast()
  return (
    <Formik
      validationSchema={emailSchema}
      initialValues={{ email: '' }}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        mutateSetup(
          { email: values.email },
          {
            onError: (error: any) => {
              if (error.code === 409) {
                setFieldValue('emailDuplicated', true)
              } else {
                toast({
                  title: 'Error',
                  description: `Your verification email must be different than the one we have saved for you.`,
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                })
              }

              setSubmitting(false)
            },
            onSuccess: () => {
              onSuccess?.()
              toast({
                title: 'Success',
                description: 'Email has been saved',
                status: 'success',
                duration: 3000,
                isClosable: true,
              })
            },
          }
        )
      }}
    >
      {({
        values,
        isValid,
        dirty,
        submitForm,
        isSubmitting,
        getFieldProps,
        setFieldValue,
        errors,
        touched,
      }) => (
        <TwoFactorModalContent
          mobileMarginTop={{ base: '174px', md: '0' }}
          desktopMarginTop={{ base: '0', md: '75px', lg: '125px' }}
          onClose={onClose}
          isOpen={isOpen}
          description="Enter your email"
          title="Setup 2-Factor Authentication"
          innerContent={
            <Form>
              <ModalBody>
                <Container px="7px">
                  <VStack mt="8" mb="4">
                    <Text lineHeight="base" textAlign="center">
                      Two-factor authentication is an enhanced security measure. Once enabled,
                      you’ll be required to provide two forms of verification to perform
                      transactions. You’ll need to provide an email address that is different than
                      the one used at login.
                    </Text>
                    <HStack spacing="3" pt="30px" align="flex-start" w="full">
                      <FormControl isRequired isInvalid={!!errors.email && !!touched.email}>
                        <FormLabel whiteSpace="nowrap">Email</FormLabel>
                        <Input
                          type="email"
                          {...getFieldProps('email')}
                          onChange={(e) => setFieldValue('email', e.target.value)}
                        />
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                      </FormControl>
                    </HStack>
                  </VStack>
                </Container>
              </ModalBody>
            </Form>
          }
          buttons={
            <BottomAction>
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                mt={5}
                width="full"
              >
                {skippable && (
                  <Button onClick={onClose} variant="outline" flex={1} mr={1}>
                    Set up later
                  </Button>
                )}
                <Button
                  isLoading={isSubmitting}
                  flex={1}
                  ml={1}
                  maxW="50%"
                  variant="light"
                  onClick={submitForm}
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  <span>Continue</span>
                </Button>
              </Flex>
            </BottomAction>
          }
        />
      )}
    </Formik>
  )
}
