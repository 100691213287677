import { Link } from '@chakra-ui/react'
import { MouseEventHandler } from 'react'

interface LinkTwitterProps {
  content: string
  customLink?: string
  blue?: boolean
  bold?: boolean
  leadingSpace?: boolean
  follow?: boolean
  onClick?: MouseEventHandler<HTMLAnchorElement>
}

export const LinkToTwitter = ({
  content,
  customLink,
  blue,
  leadingSpace,
  follow,
  onClick,
  bold,
}: LinkTwitterProps) => (
  <Link
    target="_blank"
    fontWeight={bold ? 700 : 400}
    onClick={onClick}
    color={blue ? 'blue.link' : 'highlight.default'}
    href={
      customLink ??
      `https://twitter.com/${follow ? 'intent/follow?screen_name=' : ''}${content.substring(1)}`
    }
  >
    {(follow ? 'Follow ' : '') + (leadingSpace ? ' ' : '') + content}
  </Link>
)
