const styles = {
  global: {
    html: {},
    body: {
      fontFamily: 'body',
      color: 'white',
      bg: 'black',
    },
    h1: {
      fontFamily: 'heading',
    },
    '.walletconnect-modal__base': {
      backgroundColor: '#273138!important',
    },
    '.walletconnect-qrcode__image': {
      border: '24px solid #fff!important',
      borderRadius: '8px!important',
    },
    '.walletconnect-qrcode__text': {
      color: '#fff!important',
    },
    '.walletconnect-modal__mobile__toggle': {
      background: 'hsla(0,0%,100%,.1)!important',
    },
    '.walletconnect-modal__mobile__toggle_selector': {
      background: 'hsla(0,0%,100%,.2)!important',
    },
    '.walletconnect-search__input': {
      background: 'hsla(0,0%,100%,.1)!important',
    },
    '.walletconnect-connect__button__text': {
      color: '#fff!important',
    },
    '::-webkit-scrollbar': {
      width: '10px',
    },
    '*::-webkit-scrollbar': {
      width: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      boxShadow: 'inset 0 0 2px 2px rgba(255, 255, 255, 0.4)',
      border: 'solid 3px transparent',
    },
    '*::-webkit-scrollbar-thumb': {
      boxShadow: 'inset 0 0 2px 2px rgba(255, 255, 255, 0.4)',
      border: 'solid 3px transparent',
    },
    '*': {
      scrollbarColor: 'grey transparent',
      scrollbarWidth: 'thin',
    },
    '.react-datepicker-wrapper': {
      height: '100%',
    },
    '.react-datepicker__input-container': {
      height: '100%',
    },
    '.react-datepicker__input-container input': {
      maxWidth: '155px',
      textAlign: 'center',
      paddingInlineEnd: '1.1rem',
      height: '100%',
      borderRadius: '0.375rem',
      backgroundColor: 'black',
      border: '1px solid',
      paddingInlineStart: '1.1rem',
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '.injectedMarkdown li': {
      marginLeft: '40px',
    },
    '.injectedMarkdown h1': {
      'font-size': '2rem',
      'font-weight': 'bold',
    },
    '.injectedMarkdown h2': {
      display: 'block',
      'margin-block-start': '0.83em',
      'margin-block-end': '0.83em',
      'margin-inline-start': '0px',
      'margin-inline-end': '0px',
      'font-size': '1.5rem',
      'font-weight': 'bold',
    },
    '.injectedMarkdown p': {
      display: 'block',
      'margin-block-start': '1em',
      'margin-block-end': '1em',
      'margin-inline-start': '0px',
      'margin-inline-end': '0px',
    },
    '.card-number-frame iframe': {
      height: '38px !important',
    },
    '.expiry-date-frame iframe': {
      height: '38px !important',
    },
    '.cvv-frame iframe': {
      height: '38px !important',
    },
    '.card-number-frame': {
      flex: '1 1 auto',
      'padding-left': '12px',
      'border-radius': '6px',
      border: '1px solid #999',
    },
    '.expiry-date-frame': {
      flex: '1 1 auto',
      'padding-left': '12px',
      'border-radius': '6px',
      border: '1px solid #999',
    },
    '.cvv-frame': {
      flex: '1 1 auto',
      'padding-left': '12px',
      'border-radius': '6px',
      border: '1px solid #999',
    },
    '.frame--activated': {
      opacity: 1,
      'border-radius': '6px',
      'box-shadow': '0 1px 3px 0 rgba(19, 57, 94, 0.2)',
    },

    // TODO: this is not working, fix
    '.frame--activated .cvv': {
      'box-shadow': 'initial !important',
      'background-color': 'transparent !important',
    },

    '.frame--activated.frame--focus': {
      border: 'solid 1px #63b3ed',
      'box-shadow': '0 0 0 1px #63b3ed',
    },

    '.frame--activated.frame--invalid': {
      border: 'solid 1px #FC8181',
      'box-shadow': '0 0 0 1px #FC8181',
    },
  },
}

export default styles
