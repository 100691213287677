import { Box, Heading, Stack } from '@chakra-ui/react'
import { FcGoogle } from 'react-icons/fc'
import { FaApple, FaDiscord, FaTwitch } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { AUTH_API_URL, DEFAULT_PROVIDERS, LoginProviders } from '../../../constants'
import { Button, ButtonProps } from '../../atoms'
import { useTracking } from '../../../hooks/useTracking'

interface LoginProps {
  providers?: LoginProviders[]
  noBackground?: boolean
}

interface SocialButtonProps extends ButtonProps {
  provider: string
}

const SocialSignInButton = ({ provider, ...props }: SocialButtonProps) => {
  const { track } = useTracking()
  const signIn = async (provider: string) => {
    track('login_submit', { provider })
    window.open(`${AUTH_API_URL}/v2/auth/${provider}`, '_self')
  }

  return (
    <Button
      size="full"
      variant="light"
      color="gray.800"
      onClick={() => signIn(provider.toLowerCase())}
      loadingText={<span>Loading...</span>}
      {...props}
    >
      <span> Sign In with {provider.replace('Twitter', 'X')}</span>
    </Button>
  )
}

export const LoginProvidersButtons: React.FC<LoginProps> = ({
  noBackground = false,
  providers = DEFAULT_PROVIDERS,
}) => (
  <Stack flex={1} align="center" justify="center">
    <Box
      w="full"
      bg={noBackground ? 'none' : 'container'}
      p={5}
      px={{ md: '30px' }}
      pb={{ md: '30px' }}
      borderRadius="xl"
    >
      <Heading
        borderBottom="1px"
        borderColor="divider"
        pb="22px"
        textAlign="center"
        fontWeight={400}
      >
        <b>Sign in</b> or <br />
        <b>Create an Account</b>
      </Heading>
      <Stack gap={4}>
        {providers.map((provider) => {
          switch (provider) {
            case LoginProviders.GOOGLE:
              return (
                <SocialSignInButton
                  key="provider-google"
                  provider="Google"
                  leftIcon={<FcGoogle size="20px" />}
                />
              )
            case LoginProviders.TWITCH:
              return (
                <SocialSignInButton
                  key="provider-twitch"
                  provider="Twitch"
                  leftIcon={<FaTwitch color="#9146FF" size="20px" />}
                />
              )
            case LoginProviders.DISCORD:
              return (
                <SocialSignInButton
                  key="provider-discord"
                  provider="Discord"
                  leftIcon={<FaDiscord color="#5865F2" size="20px" />}
                />
              )
            case LoginProviders.TWITTER:
              return (
                <SocialSignInButton
                  key="provider-twitter"
                  provider="Twitter"
                  leftIcon={<FaXTwitter color="#000000" size="20px" />}
                />
              )
            case LoginProviders.APPLE:
              return (
                <SocialSignInButton
                  key="provider-apple"
                  provider="Apple"
                  leftIcon={<FaApple color="#292929" size="20px" />}
                />
              )
            default:
              return null
          }
        })}
      </Stack>
    </Box>
  </Stack>
)
