import { kebabCase } from 'lodash'
import { PremiumCurrency } from 'types/vault/v2/PremiumCurrency'
import { Game } from '../types/vault/Game'
import { Game as GameV2 } from '../types/vault/v2/Game'
import { SaleGroup } from '../types/vault/SaleGroup'
import { OPENLOOT_CDN_URL } from '../constants'

const openlootUrlBase = `${OPENLOOT_CDN_URL}/open-loot`
const gameImageUrlBase = `${OPENLOOT_CDN_URL}/games`
const heroBannersUrlBase = `${OPENLOOT_CDN_URL}/hero-banners`
const saleGroupImageUrlBase = `${OPENLOOT_CDN_URL}/sale-groups`

export const getGameHeroUrlDesktop = ({ slug }: Game) =>
  `${gameImageUrlBase}/${slug}/hero-desktop.png`
export const getGameHeroUrlMobile = ({ slug }: Game) =>
  `${gameImageUrlBase}/${slug}/hero-mobile.png`
export const getGameHeroLogoUrl = ({ slug }: Game) => `${gameImageUrlBase}/${slug}/hero-logo.svg`

export const getGameBackgroundUrlDesktop = ({ slug }: Game) =>
  `${gameImageUrlBase}/${slug}/background-desktop.png`
export const getGameBackgroundUrlMobile = ({ slug }: Game) =>
  `${gameImageUrlBase}/${slug}/background-mobile.png`
export const getGameLogoUrl = ({ slug }: Game | GameV2) => `${gameImageUrlBase}/${slug}/logo.svg`

export const getInvitationStatusBgUrl = (slug: string) => ({
  sm: `${gameImageUrlBase}/${slug}-status-bg-sm.png`,
  lg: `${gameImageUrlBase}/${slug}-status-bg-lg.png`,
})
export const getInvitationLogoUrl = (slug: string) => `${gameImageUrlBase}/${slug}-logo.png`
export const getInvitationBannerUrl = (slug: string) => `${gameImageUrlBase}/${slug}-banner.png`
export const getInvitationBgUrl = (slug: string) => ({
  sm: `${gameImageUrlBase}/${slug}-invite-bg-sm.png`,
  lg: `${gameImageUrlBase}/${slug}-invite-bg-lg.png`,
})
export const getInvitationFgUrl = (slug: string) => `${gameImageUrlBase}/${slug}-invite-fg.png`
export const getSaleHeroBannerUrl = ({ name, extra }: SaleGroup) =>
  extra?.desktopBannerUrl ?? `${saleGroupImageUrlBase}/${kebabCase(name)}-hero-banner.png`

export const getSaleHeroBannerMobileUrl = ({ name, extra }: SaleGroup) =>
  extra?.mobileBannerUrl ?? `${saleGroupImageUrlBase}/${kebabCase(name)}-hero-banner-mobile.png`

export const getSalesGroupBackgroundUrl = ({ name }: SaleGroup) =>
  `${saleGroupImageUrlBase}/${kebabCase(name)}-background.png`
export const getSaleListCoverUrl = (name: string) =>
  `${saleGroupImageUrlBase}/${kebabCase(name)}-list-cover.png`
export const defaultSaleListCoverUrl = `/images/sale-list-cover-default.png`

export const getItemVideoUrl = (
  collection: string,
  optionName: string,
  metadataVideoUrl?: string,
  itemVideoUrl?: string
): string =>
  itemVideoUrl || metadataVideoUrl || `${OPENLOOT_CDN_URL}/${collection}/${optionName}.webm`

export const getItemImageUrl = (metadataImageUrl: string, itemImageUrl?: string): string =>
  itemImageUrl || metadataImageUrl

export const getSalesGroupVideoUrl = (slug: string) => `${saleGroupImageUrlBase}/${slug}.mp4`

export const getPremiumCurrencyIconUrl = ({ icon, slug }: PremiumCurrency) =>
  icon || `${OPENLOOT_CDN_URL}/premium-currencies/icons/${slug}.svg`

export const getHeroBannerGameLogo = (gameSlug: string) =>
  `${heroBannersUrlBase}/game-logos/${gameSlug}.svg`

export const getHeroBannerMobileBg = (heroSlug: string) =>
  `${heroBannersUrlBase}/${heroSlug}/bg-mobile.png`

export const getHeroBannerDesktopBg = (heroSlug: string) =>
  `${heroBannersUrlBase}/${heroSlug}/bg-desktop.png`

export const getDowloadOpenlootLauncherDesktopBg = () =>
  `${openlootUrlBase}/download-openloot-launcher.png`

export const getDowloadOpenlootLauncherMobilepBg = () =>
  `${openlootUrlBase}/download-openloot-launcher-mobile.png`
