import { Box, Flex, Text, Link } from '@chakra-ui/react'
import { Button } from 'components/atoms/Button'
import { setCookie, getCookie } from 'react-use-cookie'
import { useEffect, useState } from 'react'

const COOKIE_NOTICE_ACCEPT = 'cookie_notice_accept'

export const CookieConsentBanner = () => {
  const [cookieNoticeAccept, setCookieNoticeAccept] = useState(true)

  const cookie = getCookie(COOKIE_NOTICE_ACCEPT)

  useEffect(() => {
    setCookieNoticeAccept(cookie === '1')
  }, [cookie])

  const accept3rdPartyCookkies = () => {
    setCookie(COOKIE_NOTICE_ACCEPT, '1')
    setCookieNoticeAccept(true)
  }

  if (cookieNoticeAccept) {
    return null
  }

  return (
    <Box
      boxShadow={{ base: '0px -4px 10px 0px #0000001A;', md: 'none' }}
      __css={{ paddingBottom: 'env(safe-area-inset-bottom)' }}
      w={{ base: '80%', sm: '68%' }}
      margin="0 auto"
      pos="sticky"
      bg="linear-gradient(265.91deg, #FFFFFF 11.03%, #E5E5E5 81.77%)"
      bottom="75px"
      backdropFilter="blur(5px)"
      zIndex="banner"
      border="3px solid"
      borderColor="table-stroke"
      maxW="1062px"
    >
      <Flex
        align="center"
        height="full"
        p="20px"
        mx="auto"
        justifyContent="center"
        alignItems="center"
        gridGap="30px"
        maxW="992px"
        flexDir={{ base: 'column', sm: 'row' }}
      >
        <Text
          color="black"
          fontWeight="300"
          fontSize={{ base: '14px', sm: '16px' }}
          lineHeight={{ base: '18px', sm: '24px' }}
          fontFamily="Bai Jamjuree"
        >
          We use 3rd party cookies to improve user experience and analyze website traffic. <br />
          <Text display={{ sm: 'none' }}>
            <br />
          </Text>
          By clicking “Accept“, you agree to our website's cookie use as described in our{' '}
          <Link
            href="/privacy-policy#section-9"
            fontSize={{ base: '14px', sm: '16px' }}
            color="highlight.default"
            textDecoration="underline"
            fontWeight="700"
          >
            Cookie Policy
          </Link>
          .
        </Text>
        <Button bg="subscribe-btn" size="md" maxW="200px" w="full" onClick={accept3rdPartyCookkies}>
          Accept
        </Button>
      </Flex>
    </Box>
  )
}
