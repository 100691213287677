import { Image, HStack, UnorderedList, ListItem, Text, Box } from '@chakra-ui/react'
import Link from 'next/link'
import { Button } from 'components/atoms'
import { useLogoutMutation } from 'hooks/useLogoutMutation'
import { User } from 'types/vault/User'
import { UserMenuInfo } from 'components/organisms/UserMenuInfo'

export interface UserMenuProps {
  onClose?: () => void
  px?: number | string
  navItems: {
    name: string
    url: string
  }[]
  user: User
}

export const UserMenu: React.FC<UserMenuProps> = ({ onClose, user, px, navItems }) => {
  const { mutate } = useLogoutMutation()
  const handleLogout = () => mutate()

  return (
    <Box px={px || 5} pt="13px" pos="relative" color="menu-content" zIndex="skipLink">
      {onClose && (
        <Button size="auto" variant="none" pos="absolute" top="17px" right="21px" onClick={onClose}>
          <Image src="/images/icons/close.svg" alt="close icon" />
        </Button>
      )}
      <UserMenuInfo user={user} onCloseMenu={onClose} />
      <UnorderedList listStyleType="none" m={0} mt={3}>
        {navItems.map(({ name, url }) => (
          <Link key={name} href={url} passHref legacyBehavior>
            <ListItem
              py={3}
              borderTop="1px"
              borderColor="bright.default"
              cursor="pointer"
              opacity={0.9}
              _hover={{ fontWeight: 'bold' }}
              transition="all 0.2s"
            >
              <Text color="menu-content">{name}</Text>
            </ListItem>
          </Link>
        ))}
      </UnorderedList>
      <HStack justifyContent="space-between" py={3} borderTop="1px" borderColor="bright.default">
        <Button
          variant="none"
          size="auto"
          fontSize="xs"
          color="gray.500"
          onClick={handleLogout}
          _hover={{ color: 'gray.700' }}
        >
          Log Out
        </Button>
        <Button variant="none" size="auto" fontSize="xs" color="gray.500" onClick={handleLogout}>
          <Image src="/images/icons/logout.svg" alt="logout icon" />
        </Button>
      </HStack>
    </Box>
  )
}
